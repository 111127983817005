@import "../../../../../variables.scss";
@import "../../../../Typography/typography.scss";

.lockToolContainer {
  background-color: $greyscaleColour7;
  display: flex;
  padding: 2rem;
  width: 100%;
  max-height: 800px;
}

.header {
  display: flex;
  flex-direction: column;
}

.title {
  color: $blackColour;
}
.subtitle {
  margin-top: -25px;
  font-size: small;
}

.lockContainer {
  width: 100%;
  background-color: $whiteColour;
  border-radius: .5rem;
  overflow-x: auto;
  padding: 1rem;
}

.buttonDiv {
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  margin-top: 100px;
}

.buttonGroup {
  display: flex;
  flex: 99 0 auto;
  justify-content: flex-end;
  margin-top: $gridUnit*4;
  width: 100%;
  text-align: center;

  button, a {
    align-self: flex-end;
  }
  a {
    margin-right: 2 * $gridUnit;
  }
}

.lockButton {
  height: 100px;
  width: 200px;
  text-align: center;
  margin:0 auto;
  display: flex;
  align-items: center;
  padding: 0.5rem 2rem;
  font-size: medium;
}

.modalTitle {
  color: $blackColour;
  font-size: large;
  text-align: center;
  white-space: pre-wrap;
}

.modalText {
  color: $blackColour;
  white-space: pre-wrap;
}
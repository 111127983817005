@import "../../../../../variables.scss";

.commsScreenToolContainer {
    background-color: $greyscaleColour7;
    display: flex;
    padding: 2rem;
    width: 100%;
    max-height: 150rem;
  }
  
  .header {
    display: flex;
    flex-direction: column;
  }
  
  .title {
    color: $blackColour;
  }
  .subtitle {
    margin-top: -25px;
    font-size: small;
  }
  
  .commsScreenContainer {
    background-color: $whiteColour;
    border-radius: .5rem;
    overflow-x: auto;
    padding: 1rem;
    width: 100%;
  }
  
  .modalContainer {
    background-color: $greyscaleColour7;
  }

  .modalButtons {
    background-color: whitesmoke;
  }

  .dragAndDropContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 80%;
  }

  .dragAndDropArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    &:hover {
      cursor: pointer;
    }
  }
  
  .dragAndDropMessage {
    background-color: $greyscaleColour7;
    color: $blackColour;
    border: 2px dotted;
    text-align: center;
    width: 14rem;
    margin: 8px 0px;
  }

  .dragAndDropMessage:hover {
    background-color: $greyscaleColour6;
  }

  .metaInfoContainer {
    padding-top: 1rem;
    text-align: center;
  }
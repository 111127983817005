// FLUID PROPERTIES:
// Can be used for FLUID MARGINS, PADDING, etc across screen sizes. (Aside: For typography levels use typography.scss)

/* Calculated using methods from: https://www.smashingmagazine.com/2016/05/fluid-typography/ */
@use "sass:math";

@function _strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

@mixin fluidProperty($property, $min-value, $max-value, $min-vw, $max-vw) {
  $min-max-difference: _strip-unit($max-value) - _strip-unit($min-value);
  $vw-difference: _strip-unit($max-vw) - _strip-unit($min-vw);
  #{$property}: calc(#{$min-value} + #{$min-max-difference} * (100vw - #{$min-vw}) / #{$vw-difference});
}

@mixin fluidPropertyBPs($property, $mobileSize, $tabletSize, $smallDesktopSize, $wideDesktopSize, $extraWideDesktopSize, $superWideDesktopSize){
  #{$property}: $mobileSize;
  @media ($tablet) {
    @include fluidProperty($property, $tabletSize, $smallDesktopSize, $_tabletWidth, $_smallDesktopWidth);
  }
  @media ($smallDesktop) {
    @include fluidProperty($property, $smallDesktopSize, $wideDesktopSize, $_smallDesktopWidth, $_wideDesktopWidth);
  }
  @media ($wideDesktop) {
    @include fluidProperty($property, $wideDesktopSize, $extraWideDesktopSize, $_wideDesktopWidth, $_extraWideDesktopWidth);
  }
  @media ($extraWideDesktop) {
    @include fluidProperty($property, $extraWideDesktopSize, $superWideDesktopSize, $_wideDesktopWidth, $_extraWideDesktopWidth);
  }
  @media ($superWideDesktop) {
    #{$property}: $superWideDesktopSize;
  }
}

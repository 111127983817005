@import "../../../variables.scss";
@import "../../../components/SecondaryButton/secondaryButton";
@import "../../../components/PrimaryButton/primaryButton";
@import "../../../components/ChangeStatusButton/ChangeStatusButton";

.newProductPage {
    padding: $gridUnit;
    h1 {
        padding: $gridUnit * 5 0;
        border-bottom: 1px solid $greyscaleColour6;
        text-align: center;
    }
    .form {
        width: 50 * $gridUnit;
        margin: 0 auto;
    }
}

.contentPanelAndSketch {
    display: flex;
}

.form {
    width: 100%;
    background-color: $whiteColour;
    /*background: candyStripeBackground;*/
}

.sketchUploadArea {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $candyStripeBackground, $whiteColour;
    padding: $gridUnit * 3;
    border-left: 1px solid $greyscaleColour6;
    border-radius: $baseBorderRadius;
}

.sketchUploadButton {
    @include secondaryButton();
}

button {
  &.changeStatus {
      @include changeStatusButton();
      display: block;
      margin: 0 auto;
  }
}


.sketchUploadInput {
    position: absolute;
    left: -999rem;
    top: -999rem;
}

.contentPanel {
    background-color: $whiteColour;
    padding: $gridUnit * 3;
    border-radius: $baseBorderRadius;
}

.concentrationWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.concentrationArea {
    max-width: 800px;
}

.formField {
    margin-bottom: 0.75rem;
}

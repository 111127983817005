@import "../../variables";

.label {
  color: $greyscaleColour3;
  margin-bottom: 0;
}

.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: $gridUnit*9;
  align-items: center;
  /* NOTE: we need to do unset the height
   because Hierarchy.scss is styling svg globally */
  svg {
    height: unset;
  }

}
@import "../../../variables.scss";
@import "../../../components/Typography/typography";

.tagLink {
  &:hover, &:focus {
    filter: brightness(80%);
    outline: none;
  }
}

.tag{
  @include bodyTextReduced();
  display: flex;
  align-items: center;
  padding-right: 0.5 * $gridUnit;
}

.filterTag {
  background-color: $accentColour;
  border-radius: 1rem;
  margin: 0.2rem;

  .tagLink {
    width: 4*$gridUnit;
    border-top-left-radius: $baseBorderRadius;
    border-bottom-left-radius: $baseBorderRadius;
  }
}

.tagText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5 * $gridUnit;
}

.closeTag {
  width: 1.6rem;
  stroke: none;
  fill: rgb(198,201,23);
  padding-top: 5px;
  padding-left: 5px;
}

@import "../../../../variables.scss";


.departmentArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 $gridUnit;
  min-height: 60px;
}

.title {
  text-decoration: underline;
  color: #000;
  font-weight: bold;
}

.section {
  padding: 4*$gridUnit;
  display: flex;
  flex-direction: column;
  background-color: $greyscaleColour7;
  width: 100%;
}

.spinnerContainerStyle {
  height: calc(100vh - #{$headerDesktopHeight * 5});;
}


@import "../../../variables.scss";

$chipHeight: 30px;
$chipWidth: 130px;
.sizeChip {
  display: flex;
  background: $activeColour;
  width: $chipWidth;
  min-height: $chipHeight;
  border-radius: $chipHeight;
  justify-content: space-between;
  padding: 0 4px 0 10px;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
  cursor: pointer;
}

.lockedStatus {
  background: $blackColour;
  color: $whiteColour;
}

.disabledStatus {
  background: $greyscaleColour5;
  color: $whiteColour;
}

.commitmentErrorStatus {
  background: $errorColour;
  color: $whiteColour;
}

.sizeInfoContainer {
  display: flex;
}

.sizeText {
  font-weight: bold;
  line-height: unset;
}

.sizeGroupLabel {
  font-size: 8px;
  padding-right: 3px;
  align-self: center;
}

.sizeChipButton {
  background: $whiteColour;
  border-radius: 50%;
  text-align: center;
  display: inline-flex;
  padding: 5px;
  border: 0;
}

.removeIcon {
  width: 23px;
  height: 23px;
  margin: -5px;
}

@import "../../variables";

.active {
  background-color: $greyscaleColour7;
}

.menuListItem {
  border-bottom: 1px solid $greyscaleColour5;
  &:last-of-type {
    border-bottom: none;
  }
  &:focus {
    outline: none;
  }
}

.menuLink {
  display: inline-block;
  width: 100%;
  padding: 0.5*$gridUnit $gridUnit;
  text-align: center;
  color: $greyscaleColour2;
  text-decoration: none;
  text-transform: none;
  &:hover {
    background-color: $greyscaleColour7;
  }
}

@import "../../variables.scss";

.header {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    @supports (display: grid) {
        display: grid;
        grid-template-columns: auto 2fr 1fr auto;
        grid-gap: 2vw;
        @media ($mobileOnly) {
            display: grid;

        }
    }
    background: var(--header-bg-color);
}

.logoContainer {
    @media ($tablet) {
        margin-right: 3.5vw;
        text-align: center;
    }
    @supports (display: grid) {
        @media ($tablet) {
            margin-right: 0;
        }
    }
}

@import "../../../../variables.scss";

.closeButton {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 100%;
  background: white;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.errorList {
  flex: 1;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  justify-items: stretch;
}

.errorListItem {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  border-top: 1px solid $accentColour;
  padding: 0.5 * $gridUnit $gridUnit;
  text-align: center;
  color: $greyscaleColour2;
  text-transform: none;
  background-color: $accentColour;
  margin-bottom: 1.5rem;

  .closeButton {
    position: absolute;
    right: 0.25rem;
    width: 24px;
    height: 24px
  }
}

.errorText {
  width: 85%;
}
@import "../../../variables.scss";

@mixin visuallyHidden(){
    position: absolute;
    left: -999rem;
    top: -999rem;
}

@mixin visuallyShown(){
    position: static;
    left: auto;
    top: auto;
}
@import "../../variables.scss";

.sizeModal {
  background-color: #f0f0f0;
  width: 90%;
}

.sizeInformation {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 400px;
  overflow-y: auto;
  overflow-x: auto;

  .loadingPanel {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 300px;
    justify-content: center;
    align-items: center;
  }
}

@import "../../variables.scss";

$adminHeaderHeight: 10*$gridUnit;

.admin {
  background-color: #ffffff;
  height: calc(100vh - #{$headerDesktopHeight});
  color: #959595;
}

.adminBody {
  display: flex;
  height: calc(100vh - #{$headerDesktopHeight + $adminHeaderHeight});
}

.spinnerContainer {
  height: calc(100vh - #{$headerDesktopHeight * 2.2});;
}

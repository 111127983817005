@import "../../variables.scss";

.tableContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$aboveTableControlsHeightWideDesktop} - #{$headerDesktopHeight});
  @media ($tablet) {
    height: calc(100vh - #{$aboveTableControlsHeightTablet} - #{$headerTabletHeight});
  }
  @media ($smallDesktop) {
    height: calc(100vh - #{$aboveTableControlsHeightSmallDesktop} - #{$headerDesktopHeight});
  }
  @media ($wideDesktop) {
    height: calc(100vh - #{$aboveTableControlsHeightWideDesktop} - #{$headerDesktopHeight});
  }
  @media ($extraWideDesktop) {
    height: calc(100vh - #{$aboveTableControlsHeightExtraWideDesktop} - #{$headerDesktopHeight});
  }
  @media ($superWideDesktop) {
    height: calc(100vh - #{$aboveTableControlsHeightSuperWideDesktop} - #{$headerDesktopHeight});
  }
}

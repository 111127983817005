@import "../../../../../../variables";

.container {
  display: flex;
  padding: 2 * $gridUnit;
  justify-content: space-between;
  flex-direction: column;
}

.attributeName {
  align-self: flex-start;
  padding-bottom: 2*$gridUnit;
}

.link {
  color: $activeColour;
  text-decoration: underline;
  font-weight: bold;
  &:hover, &:focus, &:active {
    outline: unset;
    color: $activeColour;
  }
}

.attributeInfo {
  display: flex;
  justify-content: space-evenly;
  flex: 1 1;
}

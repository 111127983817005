.deprecatedValue {
  text-decoration: line-through;
}

.copyIconContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;

  .copyIcon {
    width: 16px;
    height: 16px;
  }
}

.clipboardEnableElement {
  color: #eef0ef;
  font-size: 2px;
  height: 2px;
}
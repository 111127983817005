@import "../../variables";

.textFormField {
  width: 100%;
  margin-bottom: $gridUnit*1.5;
}

.label {
  color: $greyscaleColour3;
  margin-bottom: 0;
}

.validationMessage {
  width: auto;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  padding: $gridUnit;
  background-color: $whiteColour;
  color: $errorColour;
  box-shadow: $baseBoxShadow;
  &::first-letter {
    text-transform: uppercase;
  }
}
@import "../../variables";

.checkBoxLink {
  color: $blackColour;
  text-decoration: none;
}

.wrapper {
  align-items: center;
  background-color: $whiteColour;
  border-radius: $baseBorderRadius * 2;
  display: flex;
  flex-direction: row;
  margin-right: $gridUnit;
  padding: $gridUnit;
  width:7rem;
  cursor: pointer;
  height: 100%;
}

.checkboxChecked, .checkboxNotChecked {
  width: 3.5*$gridUnit;
  border-radius: 100%;
}

.checkBoxLink:focus {
  outline: none
}

.checkboxDisabled {
  cursor: default;
  opacity: 0.4;
}

.label {
  cursor: pointer;
  display: inline-block;
  flex-grow: 1;
  text-align: center;
  margin-left: $gridUnit;
  font-size: 12px;
  line-height: 1.5;
}

.littleIcon {
  width: 2.5*$gridUnit;
}
@import "../../variables";

.drawerContainer {
  @media ($tablet) {
    width: 64.5*$gridUnit!important;
  }
  top: 9*$gridUnit!important;
  height: calc(100vh - 72px)!important;
}

.overlay {
  @media only screen and ($mobileOnly) {
    left: 0!important;
    right: 0!important;
  }
  background-color: transparent;
}

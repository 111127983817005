@import "../../../../SizeChip/SizeChip";

.productNumberSpan {
  display: flex;
  flex: 1;
  width: $chipWidth;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
}

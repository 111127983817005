@use "sass:math";

@import "../../../../variables";
@import "../../../../components/SecondaryButton/secondaryButton";


.statusText {
  @include bodyText(0.8);
  font-weight: 600;
  font-family: $bodyFontStackLight;
  margin-top: $gridUnit;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 $gridUnit;
  border-radius: $baseBorderRadius * 0.5;
  
  &.development {
    color: $successColour;
    margin-top: 0;
    margin-bottom: -$gridUnit*0.5;
  }

  &.cancelled {
    margin-top: 0;
  }

  &.proposed {
    border: 1px solid $successColour;
    color: $successColour;
    margin-top: 0;
    margin-right: $gridUnit;
  }

  &.readyToBuy {
    padding: $gridUnit*0.5 $gridUnit math.div($gridUnit, 3) $gridUnit;
    background-color: $successColour;
    color: $whiteColour;
    border-radius: $baseBorderRadius;
    border: 1px solid $successColour;
    margin: 0 $gridUnit 0 auto;
  }
}
@import "../../variables.scss";
@import "../../components/Typography/typography.scss";
@import "../../components/StyleHelpers/BlackAndWhiteInteractivity/blackAndWhiteInteractivity.scss";

@mixin secondaryButton() {
  display: inline-block;
  @include bodyText();
  padding: $gridUnit*0.5 $gridUnit*1.5;
  color: #222;
  background-color: $whiteColour;
  border: 2px solid $blackColour;
  border-radius: $baseBorderRadius;
  text-decoration: none;
  cursor: pointer;
  &:hover, &:focus, &:active {
    @include blackAndWhiteInteractivity();
    border: 2px solid $successColour;
  }

  &:hover {
    background-color: $greyscaleColour4;
    border: 2px solid $greyscaleColour4;
  }

  &:disabled {
    background-color: $whiteColour;
    border: 2px solid $greyscaleColour4;
    color: $greyscaleColour4;
    cursor: not-allowed;
    &:hover {
      box-shadow: none;
    }
  }
}


.button {
    @include secondaryButton();
}

@import "./variables.scss";
@import "./components/Typography/typography";
@import "./components/Typography/baseTagsTypography";

*, *::before, *:after {
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  @include bodyText();
  font-family: $bodyFontStackRegular;
  background-color: $screenBackgroundColour;
  color: $bodyTextColour;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

button, a {
  cursor: pointer;
}

@include baseTagsTypography();
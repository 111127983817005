.notification {
  top: 50% !important;
  left: 50% !important;
  bottom: auto !important;
  & > div * {
    font-size: 24px !important;
    font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    line-height: 1.6 !important;
    text-align: center !important;
    padding: 24px !important;
  }
}

.success > div {
  background-color: #41B97A !important;
  height: auto !important;
}

.error > div {
  background-color: #FF586D !important;
  height: auto !important;
}
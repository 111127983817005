@import "../../../../variables.scss";

$adminHeaderHeight: 10*$gridUnit;

.attributeDisplay {
  overflow-y: overlay;
  height: 100%;
  padding: 4 * $gridUnit;
  padding-right: 2 * $gridUnit;
  flex: auto;
  background-color: $greyscaleColour7;
}

.valuesList {
  color: $blackColour;
  font-weight: bold;
  overflow-y: overlay;
  max-height: 29 * $gridUnit;

  .deprecatedListItem {
    text-decoration: line-through;
  }
}

.value {
  color: $blackColour;
  font-weight: bold;
  text-transform: capitalize;
}

.attributeDisplayName {
  text-decoration: underline;
  color: $blackColour;
  font-weight: bold;
}

.attributeKey {
  padding-right: 12*$gridUnit;
}

.attributeKeyValue {
  margin-top: 6*$gridUnit;
}

.titleAndEditWrapper {
  display: flex;
  .attributeDisplayName {
    flex: 99 0 auto;
  }
}

.editIconButton {
  flex: 0 99 auto;
  height: 5 * $gridUnit;
  width: 5 * $gridUnit;
  border-radius: 100%;
  padding: 0.5 * $gridUnit;

  &:disabled {
    .editIcon {
      path, circle {
        fill: $greyscaleColour5;
      }
    }
  }
}

.editIcon {
  width: 70%;
  height: 70%;
  vertical-align: middle;
}
@import "../../variables.scss";
@import "../StyleHelpers/Ripple/ripple.scss";
@import "../StyleHelpers/Accessibility/accessibility.scss";
@import "../../components/Typography/typography.scss";

$primaryNavDesktopHeight: $headerDesktopHeight;
$primaryNavMobileHeight: $headerMobileHeight;

.primaryNavigation {
  display: flex;
  @media ($mobileOnly) {
    order: -1;
  }
}

.mobileMenuTrigger {
  display: none;
  @media ($mobileOnly) {
    display: none;
    align-items: center;
    margin-left: 2*$gridUnit;
    color: $whiteColour;
    text-decoration: none;
  }
}

.mobileMenuTriggerText {
  @include visuallyHidden();
}

.mobileMenuIcon {
  margin-right: $gridUnit;
  svg {
    height: 24px;
    width: auto;
  }
}

.mobileMenuOpen {
  @media ($mobileOnly) {
    .list {
      display: flex;
    }
  }
}

.mobileMenuClosed {
  @media ($mobileOnly){
    .list {
      display: none;
    }
  }
}

.list {
  display: flex;
  padding: 0;
  @media ($mobileOnly){
    background-color: rgba(0, 0, 0, .8);
    flex-direction: column;
    position: absolute;
    top: $primaryNavMobileHeight;
    left: 0;
    width: 100vw;
    z-index: $baseZIndex+10;
  }
}

.list li {
  width: 100%;
  @media ($tablet){
    width: auto;
  }
}

.link {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: $primaryNavDesktopHeight;
  @include h4();
  margin: 0 $gridUnit*0.5;
  white-space: nowrap;
  text-decoration: none;
  color: $whiteColour;
  font-weight: 500;
  @media ($mobileOnly){
    width: 100%;
    height: $primaryNavMobileHeight;
    margin-left: 0;
    padding-left: $gridUnit*0.5;
    border-top: 1px solid $whiteColour;
  }
  @include ripple;
}

.link.linkActive {
  box-shadow: inset 0 -4px $accentColour;
  @media ($mobileOnly){
    box-shadow: inset -4px 0 1px $accentColour;
  }
}

.span {
  padding: $gridUnit;
}

// .linkActive .span {
  //text-shadow: 0 0 2px #ffffff;
// }

@import "../../../variables";

.sizesSpan {
  width: 100%;
  height: 100%;

  display: inline-flex;

  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.arrowsContainer {
  display: inline-flex;
}

.arrow {
  width: 6px;
  height: 6px;
  fill: $greyscaleColour4;
  &.back {
    margin: 0 1px 0 4px;
    -moz-transform:    scaleX(1);
    -o-transform:      scaleX(1);
    -webkit-transform: scaleX(1);
    transform:         scaleX(1);
  }
  &.forward {
    margin: 0 4px 0 1px;
    -moz-transform:    scaleX(-1);
    -o-transform:      scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform:         scaleX(-1);
  }
}
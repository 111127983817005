@import "../../../variables";

.cellValue{
  width: 100%;
}

.cell {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 3 * $gridUnit;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  input {
    width: 100%;
    height: 100%;
    text-align: center;
    border: 1px solid $greyscaleColour7;
    font: inherit;
    &:hover,
    &:focus,
    &:active {
      border-color: $activeColour;
      outline: none;
    }
  }
}

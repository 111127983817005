@import '../../../variables.scss';

$rangeRowHeight: 4rem;

.rangesContainer {
  background-color: $whiteColour;
  display: flex;
  width: 100%;
  padding: 1rem;

  .rangesNameContainer {
    overflow: auto;
    flex: 0 0 30%
  }
  .rangeRow {
    height: $rangeRowHeight;
    border-bottom: 1px solid $greyscaleColour7;
    padding-right: 2rem;
  }

  .rangeTitle {
    display: flex;
    height: 100%;
    align-items: center;
  }

  .rangesSizesContainer {
    overflow: auto;
    flex: 0 0 70%
  }

  .rangeSizes {
    height: $rangeRowHeight;
    display: flex;
    .size {
      text-decoration: none;
      color: $blackColour;
      justify-content: center;
      align-items: center;
      display: flex;
      border-bottom: 1px solid $greyscaleColour7;

      &:hover,
      &:focus {
        cursor: pointer;
        filter: brightness(80%);
        outline: none;
        background-color: $greyscaleColour7;
      }

      .sizeName {
        text-align: center;
        width: 6rem;
      }

      .sizeIcon {
        width: 2rem;
        display: flex;
      }
    }

    .deprecatedIcon {
      height: 2*$gridUnit;
      width: 4*$gridUnit;
      padding-right: 4px;

      :focus {
        outline: none;
      }
    }
  }
}
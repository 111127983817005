
@mixin baseTagsTypography(){
  @font-face {
    font-family: 'Proxima Nova';
    src:  url('~src/fonts/proximanova-regular-webfont.woff2') format('woff2'), url('~src/fonts/proximanova-regular-webfont.woff') format('woff');
  }

  @font-face {
    font-family: 'Proxima Nova Light';
    src:  url('~src/fonts/proximanova-light-webfont.woff2') format('woff2'), url('~src/fonts/proximanova-light-webfont.woff') format('woff');
  }

  @font-face {
    font-family: 'Proxima Nova Bold';
    src:  url('~src/fonts/proximanova-bold-webfont.woff2') format('woff2'), url('~src/fonts/proximanova-bold-webfont.woff') format('woff');
  }

  // BASE TAGS ONLY
  // DO NOT add other classNames here. Set them in the component where you need them using e.g. @include h3() from typography.scss

  h1, .h1 {
    @include h1;
    font-weight: normal;
    margin-top: 0;
  }

  h2, .h2 {
    @include h2;
    font-weight: normal;
    margin-top: 0;
  }

  h3, .h3 {
    @include h3;
    font-weight: normal;
    margin-top: 0;
  }

  h4, .h4 {
    @include h4;
    font-weight: normal;
    margin-top: 0;
  }

  h5, .h5 {
    @include h5;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  h6, .h6 {
    @include h6;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  p {
    margin-top: 0;
  }
}

@import "../../../variables.scss";

.readOnly {
  padding: 3px;
  line-height: 3 * $gridUnit;
  height: 9 * $gridUnit;
  overflow: hidden;
  position: relative;
}

.deprecatedValue {
  text-decoration: line-through;
}

.readOnly:after {
  content: "";
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 3 * $gridUnit;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 70%);
}

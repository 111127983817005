@import "../../../variables";
@import "../../../components/SecondaryButton/secondaryButton";

.status {
  display: flex;
  align-items: center;

  .trigger {
    text-decoration: none;
    font-weight: 700;
    &:active {
      outline: none;
    }
  }

  .icon {
    height: 2*$gridUnit;
    width:  2*$gridUnit;
    vertical-align: middle;
  }
}
@import "../../../variables";
@import "../../../components/SecondaryButton/secondaryButton.scss";

.commentsPanel{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100vh - 190px);
}

.postCommentSection{
  background-color: $greyscaleColour7;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: $gridUnit 6*$gridUnit;
  align-items: center;
  textarea {
    padding: $gridUnit;
    width: 100%;
    height: 9*$gridUnit;
    margin-top: $gridUnit;
    margin-bottom: $gridUnit;
  }
  a {
    @include bodyText();
    text-align: center;
    display: inline-block;
    padding: $gridUnit*0.5 $gridUnit*1.5;
    border-radius: $baseBorderRadius;
    border: unset;
    text-decoration: none;
    cursor: pointer;
    width: 20*$gridUnit;
    align-self: flex-end;
    background-color: $successColour;
    color: $whiteColour;
    &:hover, &:focus, &:active {
      box-shadow: $baseHeavyBoxShadow;
      outline: none;
    }
    &.disabledSaveButton {
      background-color: $greyscaleColour45;
      box-shadow: unset;
    }
  }
}

.commentsSection{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 340px);
  width: 100%;
  overflow-y: auto;
  background-color: $greyscaleColour4;
  border-bottom: 1px solid $greyscaleColour3;
  border-top: 1px solid $greyscaleColour3;
}

.textArea {
  resize: none;
  font-family: inherit;
  &:hover, &:focus, &:active {
    border: 1px solid $activeColour;
    box-shadow: $baseHeavyBoxShadow;
    outline: none;
  }
}

.spinner {
  margin-top: 20%
}
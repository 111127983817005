@import "../../../variables";

.header {
  width: 90%;
  padding-bottom: 2*$gridUnit;
  border-bottom: 1px solid $greyscaleColour34;
  margin-bottom: $gridUnit*0.25;
}

.headerTitle {
  text-decoration: underline;
  margin-bottom: 2*$gridUnit;
}

.separator {
  padding-top: 3 * $gridUnit;
  padding-bottom: 2 * $gridUnit;
  display: block;
}

.linksSectionContainer {
  width: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  height: 100%;
}

.linksSection {
  width: 90%;
  padding-bottom: 4 * $gridUnit;
}

.linksSectionWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linksSection hr {
  border-top: 1px solid $greyscaleColour4;
  border-bottom: none;
}
.spinner {
  margin-top: 50%;
}

@import "../../../variables.scss";

.footer {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.saveButton {
  margin-left: $gridUnit;
}

.saveButton,
.cancelButton {
  width: 6rem;
  padding: $gridUnit 2 * $gridUnit;
}
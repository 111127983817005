@import "../../variables.scss";

.popover {
  position: relative;
}

.menuTrigger {
  &:active {
    outline: none;
  }

  &.disabled {
    opacity: 0.4;
  }
}

.overlay {
  cursor: default;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: block;
  z-index: 2;
}

.popoverContainer {
  z-index: 3;
  display: inline-block;
  position: absolute;
  top: 7 * $gridUnit;
  padding: $baseBorderRadius 0;
  list-style-type: none;
  background-color: $whiteColour;
  box-shadow: $baseBoxShadow;
  border-radius: $baseBorderRadius;
  &::before {
    content: "";
    position: absolute;
    top: -2 * $gridUnit;
    display: block;
    width: 5.5 * $gridUnit;
    height: 2.5 * $gridUnit;
    border-style: solid;
    border-width: 0 2.25 * $gridUnit 1.5 * $gridUnit 2.25 * $gridUnit;
    border-color: transparent transparent $whiteColour transparent;
  }
  &:focus {
    outline: none;
  }
}

.popoverContainer {
  &.filterRight {
    top: 5 * $gridUnit;
    right: -5.8 * $gridUnit;
  }
  &.filterRight::before {
    z-index: 1;
    right: 4.5 * $gridUnit;
  }

  &.filterLeft {
    top: 5 * $gridUnit;
    right: -24 * $gridUnit;
  }
  &.filterLeft::before {
    z-index: 1;
    right: 22.8 * $gridUnit;
  }

  &.topLeft {
    left: 0.5 * $gridUnit;
  }
  &.topLeft::before {
    left: 4.5 * $gridUnit;
  }

  &.topRight {
    right: 0.5 * $gridUnit;
  }
  &.topRight::before {
    right: 4.5 * $gridUnit;
  }

  &.topLeft {
    left: 0.5 * $gridUnit;
  }
  &.topLeft::before {
    left: 4.5 * $gridUnit;
  }
  &.leftTop {
    top: 0;
    left: 5 * $gridUnit;
  }
  &.leftTop::before {
    width: 0;
    height: 0;
    left: -1.5 * $gridUnit;
    top: 1.7 * $gridUnit;
    border-width: 0 $gridUnit $gridUnit $gridUnit;
    transform: rotate(-90deg);
  }
  &.rightTop::before {
    width: 0;
    height: 0;
    right: -1.5 * $gridUnit;
    top: 1.7 * $gridUnit;
    border-width: 0 $gridUnit $gridUnit $gridUnit;
    transform: rotate(90deg);
  }
}

@import "../../../../variables.scss";

.header {
  display: flex;
  cursor: default;
  justify-content: center;
}

.filterLink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2*$gridUnit;
  height: 2*$gridUnit;
  margin-left: 0.5*$gridUnit;
}

.headerText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.downTriangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: $gridUnit 0.75*$gridUnit 0 0.75*$gridUnit;
}

.filterLinkOpened {
  .downTriangle {
    border-color: $tableHeaderColour transparent transparent transparent;
  }
}
.filterLinkClosed {
  .downTriangle {
    border-color: $greyscaleColour4 transparent transparent transparent;
  }
}

.popoverContainer {
  text-align: left;
  padding: 2 * $gridUnit;
  width: 32.5 * $gridUnit
}
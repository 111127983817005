@import "../../../variables.scss";

@keyframes ripple {
    0% {
        transform: scale(0, 0);
        opacity: 1;
    }
    20% {
        transform: scale(25, 25);
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: scale(40, 40);
    }
}

@mixin ripple(){
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: $gridUnit * 0.5;
        height: $gridUnit * 0.5;
        background: rgba(255, 255, 255, .5);
        opacity: 0;
        border-radius: 100%;
        transform: scale(1, 1) translate(-50%);
        transform-origin: 50% 50%;
    }

    &:focus:not(:active)::after {
        animation: ripple 1s ease-out;
    }
}
@import "../../variables.scss";
@import "../../components/StyleHelpers/BlackAndWhiteInteractivity/blackAndWhiteInteractivity";
@import "../../components/Typography/typography";

$treeItemHeight: 4.25*$gridUnit;

.loadingHierarchy {
  align-items: center;
}
.listItem {
  margin-left: 3*$gridUnit;
  @media only screen and ($mobileOnly) {
    margin-left: $gridUnit;
  }
}

.toggleHierarchy {
  font-weight: 500;
}

// Our class
.hierarchyContainer {

}

// Inside the library
.hierarchy-container {
  height: 100%;
  width: 80vw;
  @media only screen and ($mobileOnly) {
    width: 100vw;
    padding: 2*$gridUnit $gridUnit;
    .closeButtonContainer {
      display: flex;
      justify-content: flex-end;
    }
    .closeButton {
        width: 30px;
        height: 30px;
        border: none;
        border-radius: 100%;
        background: $greyscaleColour6;
        padding: 0;
        justify-content: right;
        &:focus {
          outline: none;
        }
      }
  }
  div {
    outline: none;
    &:focus {
      outline: none;
    }
  }
  & > .tree-item-wrapper:last-child{
    padding-bottom: 50px;
  }
}

.rotateLeft {
  transform: rotate(-90deg);
}

.nodeExpander {
  &:hover, &:focus {
    .arrow {
      path {
        fill: $activeColour;
      }
    }
    .folder {
      path {
        stroke: $activeColour;
      }
    }
    .notInUseFolder {
      path {
        stroke: $activeColour;
      }
    }
  }
  &:focus {
    outline: none;
    //@include blackAndWhiteInteractivity();
  }
}

.notInUseFolder {
  width: 24px;
  height: 24px;
}

.levelCode {
  color: $greyscaleColour3;
  @include bodyTextReduced();
}

.treeItem {
  align-items: center;
  display: flex;
  height: 2.08rem;
  padding: $gridUnit*0.5 $gridUnit;
  white-space: nowrap;
}

.tree-item-wrapper {
  box-sizing: content-box;
  cursor: pointer;
  .folder path {
    fill: $whiteColour;
    stroke: $blackColour;
  }
}

.tree-item-label {
  margin-left: $gridUnit*0.5;
  padding-left: $gridUnit*0.5;
  padding-right: $gridUnit*0.5;
  text-decoration: none;
  &:visited {
    color: unset;
  }
  &:hover, &:focus {
    text-decoration: underline;
    cursor: pointer;
    color: $activeColour;
    //background: $productFocusColour;
    .levelCode {
      color: $activeColour;
    }
  }
  &:focus {
    outline: none;
  }
}

.non-interactive-label {
  margin-left: $gridUnit*0.5;
  padding-left: $gridUnit*0.5;
  padding-right: $gridUnit*0.5;
  cursor: pointer;
  color: $greyscaleColour3;
  @include bodyTextReduced();
  text-transform: uppercase;
  letter-spacing: 1px;
}

.leafNode {
  margin-left: 24px;
}

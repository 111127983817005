@import "../../../variables.scss";

.skipLink {
  color: $activeColour;
  padding: $gridUnit;
  border: 0.5*$gridUnit solid $greyscaleColour9;
  border-radius: 0.5 * $gridUnit;
  &:hover, &:focus, &:active {
    border: 0.5*$gridUnit solid $activeColour;
    outline: none;
  }
  &:hover {
    box-shadow: $baseBoxShadow;
  }
}
@use "sass:math";

@import "../../../../variables";
@import "../../../../components/SecondaryButton/secondaryButton";

.statusText {
  @include bodyText(0.8);
  font-weight: 600;
  font-family: $bodyFontStackLight;
  margin-top: $gridUnit;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 $gridUnit;
  border-radius: $baseBorderRadius * 0.5;
}

.retryButton {
  display: flex;
  align-items: center;
  padding: $gridUnit*0.5 $gridUnit+1 math.div($gridUnit, 3) $gridUnit+1;
  background-color: $accentColour;
  color: $blackColour;
  border-radius: $baseBorderRadius;
  border: 1px solid $accentColour;
  margin: 0 $gridUnit 0 auto;
}


.smallRedoIcon {
  height: 2.0*$gridUnit;
  width: 2.0*$gridUnit;
  margin-right: 5px;
}
@import "../../variables.scss";
@import "../../components/StyleHelpers/PageGrid/pageGrid.scss";
@import "../../components/StyleHelpers/Panel/panel.scss";
@import "../../components/StyleHelpers/Accessibility/accessibility.scss";
@import "../../components/Typography/typography.scss";

.homePage {
  background-color: $whiteColour;
  background-position: top right;
  background-size: cover;
  min-height: calc(100vh - #{$headerDesktopHeight});
  position: relative;
  @media ($mobileOnly) {
    overflow-y: hidden;
  }
}

.grid {
  display: grid;
  grid-gap: 2*$gridUnit;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  height: calc(100vh - #{$headerDesktopHeight});
  @supports not (display: grid) {
    background-color: $screenBackgroundColour;
    width: 100%;
  }
  @media ($mobileOnly) {
    height: calc(100vh - #{$headerMobileHeight});
  }
  @media ($tablet){
    grid-gap: 2*$gridUnit;
    grid-template-columns: 1fr [columnName2] 1fr 1fr [columnName4] 1fr;
    grid-template-rows: [rowName1] 1fr [rowName2] 1fr [rowName3] 1fr [rowName4] 1fr;
  }
  .gridItem:not(.card) {
    background-position: center top;
    background-size: cover;
    position: relative;
    @supports not (display: grid) {
      display: none;
    }
    &:first-of-type {
      @media ($tablet){
        grid-row: rowName1 / span 2;
      }
    }
  }
  .gridItem {
    &:nth-of-type(7n) {
      @media ($tablet){
        grid-row: rowName3 / span 2;
        grid-column: columnName4;
      }
    }
  }
  .imageDesktop {
    @media ($mobileOnly) {
      display: none;
    }
  }

  .card {
    align-items: center;
    background-color: $whiteColour;
    border-radius: $baseBorderRadius;
    border: none;
    box-shadow: $baseHeavyBoxShadow;
    color: $blackColour;
    display: flex;
    font-family: $bodyFontStackRegular;
    line-height: $baseBodyLineHeight;
    overflow: hidden;
    padding: 0 $gridUnit * 3;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: all .2s ease-in-out;
    z-index: 0;
    @supports not (display: grid) {
      margin: 2*$gridUnit auto;
      padding: 2*$gridUnit;
      width: 40%;
      &:first-of-type {
        margin-top: 8*$gridUnit;
      }
    }
    &:hover, &:focus {
      transform: scale(1.015);
      z-index: 1;
    }
    &:active {
      z-index: 1;
      transform: scale(0.95);
    }
    @media ($tablet){
      grid-column: columnName2 / span 2;
      grid-row: rowName2;
      &:last-of-type {
        grid-column: columnName2 / span 2;
        grid-row: rowName3;
      }
    }
  }
  .cardDescription {
    font-size: .9rem;
  }
}

.devTrackerCard {
  @media ($mobileOnly) {
    margin: 1rem 1rem 0 1rem;
  }
}

.optionLibraryCard {
  @media ($mobileOnly) {
    margin: 0 1rem;
  }
}

.wrapper {
  align-items: center;
  display: flex;
  padding-right: $gridUnit * 3;
  position: absolute;
}

.ribbon {
  display: inline-block;
  vertical-align: middle;
  @include bodyTextReduced();
  border-radius: $baseBorderRadius;
  color: $greyscaleColour4;
  margin: 0 0 0 $gridUnit;
}

.cardGraphic {
  border-radius: 100%;
  height: auto;
  width: 40%;
  @media ($tablet) {
    margin-left: $gridUnit;
  }
  svg {
    border-radius: 100%;
  }
}

.devTrackerLogo, .optLibLogo {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 100%;
}

.cardContent {
  margin: 0 $gridUnit;
  width: 80%;
  p:last-of-type {
    margin-bottom: 0;
  }
}

.pageTitle {
  @include visuallyHidden;
}

.createButtonWrapper {
  display: flex;
  justify-content: center;
}

.createButton {
  display: none;
  @media ($mobileOnly) {
    background: $accentColour;
    border-color: transparent;
    border-radius: 6px;
    bottom: 30px;
    display: block;
    font-family: $bodyFontStackBold;
    font-size: 16px;
    padding: 1rem 0;
    position: fixed;
    text-transform: uppercase;
    width: 90%;
  }
}

.createIcon {
  width: 1rem;
  height: 1rem;
}

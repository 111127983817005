@import "../../../../../../variables.scss";
@import "../../../../../../components/Typography/typography.scss";

input {
  @include bodyText();
  display: block;

  min-height: $gridUnit*6;

  box-sizing: border-box;
  padding: $gridUnit*0.5 $gridUnit*1.5;
  border: 1px solid $greyscaleColour7;
  &:hover,
  &:focus,
  &:active {
    border-color: $activeColour;
    outline: none;
  }
}

.priceInput {
  input {
    width: 100%;
  }
}

.priceDropdown {
  width: 100%;
}

.valueContainer {
  margin-top: auto;
  margin-bottom: auto;
}

.disabled {
  color: $greyscaleColour34
}
@import "../../../../variables";

.actionCell {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.active .ellipses {
  box-shadow: $baseHeavyBoxShadow;
}

.menu {
  flex: 1;
}

.menuWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-items: stretch;
}

.menuWrapper:focus,
.menu:focus {
  outline: none;
}

.popoverContainer {
  min-width: 16 * $gridUnit;

  &.hasProductError {
    min-width: 35 * $gridUnit;
  }
}

.hasError {
  background-color: $accentColour;
}

.ellipses {
  height: 31px;
  width: 31px;
  border-radius: 100%;
  &:hover,
  &:focus,
  &:active {
    box-shadow: $baseHeavyBoxShadow;
  }
}

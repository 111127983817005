@import "../../variables.scss";
@import "../StyleHelpers/Panel/panel.scss";
@import "../../components/StyleHelpers/Accessibility/accessibility.scss";

.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-x: hidden;
    z-index: 2;
}

.noAccessPage {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-height: calc(100vh - #{$headerDesktopHeight});
    text-align: center;
    z-index: 3;
}

.noAccessPanel {
    @include panel();
}

.percentageContainer {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0 10px 0;

    .percentageCircle {
        width: 200px;
        height: 200px;
        margin: auto;
    }
    .percentageText {
        position: absolute;
        width: 100%;
        font-size: 24px;

    }

}
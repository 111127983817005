.partialFilterInput {
  input {
    color: white;
    border-radius: 1.2rem;
    border: none;
    background-color: rgba(0, 0, 0, 0.3);
    min-height:initial;
    height:46px;
    padding-bottom:0;
    padding-top:0;
  }
  input::placeholder {
    color: white;
    font-size: 15px;
  }
}


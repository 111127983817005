@import "../../variables.scss";
@import "../StyleHelpers/FluidProperty/fluidProperty";

// ----- PUBLIC -----
/* Typography hierarchy levels (scale) is based on Minor Third scale (1.2) see: https://type-scale.com/ */
@mixin h1($reductionFactor: 1){
    $wideDesktopSize: 2.074rem * $reductionFactor; //33.184px
    $mobileSize: $wideDesktopSize*0.8; //?
    $tabletSize: $mobileSize; //?
    $smallDesktopSize: $wideDesktopSize*0.9; //?
    $extraWideDesktopSize: $wideDesktopSize*1.1;//?
    $superWideDesktopSize: $wideDesktopSize*1.1; //?
    @include fluidPropertyBPs(font-size, $mobileSize, $tabletSize, $smallDesktopSize, $wideDesktopSize, $extraWideDesktopSize, $superWideDesktopSize);
    $line-height: 1.3;
    @include fluidPropertyBPs(line-height, $mobileSize*$line-height, $tabletSize*$line-height, $smallDesktopSize*$line-height, $wideDesktopSize*$line-height, $extraWideDesktopSize*$line-height, $superWideDesktopSize*$line-height);
}

@mixin h2($reductionFactor: 1){
    $wideDesktopSize: 1.728rem * $reductionFactor; //27.648px
    $mobileSize: $wideDesktopSize*0.8; // ?
    $tabletSize: $mobileSize; //?
    $smallDesktopSize: $wideDesktopSize*0.9; //?
    $extraWideDesktopSize: $wideDesktopSize*1.1;//?
    $superWideDesktopSize: $wideDesktopSize*1.1; //?
    @include fluidPropertyBPs(font-size, $mobileSize, $tabletSize, $smallDesktopSize, $wideDesktopSize, $extraWideDesktopSize, $superWideDesktopSize);
    $line-height: 1.3;
    @include fluidPropertyBPs(line-height, $mobileSize*$line-height, $tabletSize*$line-height, $smallDesktopSize*$line-height, $wideDesktopSize*$line-height, $extraWideDesktopSize*$line-height, $superWideDesktopSize*$line-height);
}

@mixin h3($reductionFactor: 1){
    $wideDesktopSize: 1.44rem * $reductionFactor; //23.04px
    $mobileSize: $wideDesktopSize*0.8; // ?
    $tabletSize: $mobileSize; //?
    $smallDesktopSize: $wideDesktopSize*0.9; //?
    $extraWideDesktopSize: $wideDesktopSize*1.1;//?
    $superWideDesktopSize: $wideDesktopSize*1.1; //?
    @include fluidPropertyBPs(font-size, $mobileSize, $tabletSize, $smallDesktopSize, $wideDesktopSize, $extraWideDesktopSize, $superWideDesktopSize);
    $line-height: 1.3;
    @include fluidPropertyBPs(line-height, $mobileSize*$line-height, $tabletSize*$line-height, $smallDesktopSize*$line-height, $wideDesktopSize*$line-height, $extraWideDesktopSize*$line-height, $superWideDesktopSize*$line-height);
}

@mixin h4($reductionFactor: 1){
    $wideDesktopSize: 1.2rem * $reductionFactor; //19.2px
    $mobileSize: $wideDesktopSize*0.8; // ?
    $tabletSize: $mobileSize; //?
    $smallDesktopSize: $wideDesktopSize*0.9; //?
    $extraWideDesktopSize: $wideDesktopSize*1.1;//?
    $superWideDesktopSize: $wideDesktopSize*1.1; //?
    @include fluidPropertyBPs(font-size, $mobileSize, $tabletSize, $smallDesktopSize, $wideDesktopSize, $extraWideDesktopSize, $superWideDesktopSize);
    $line-height: 1.3;
    @include fluidPropertyBPs(line-height, $mobileSize*$line-height, $tabletSize*$line-height, $smallDesktopSize*$line-height, $wideDesktopSize*$line-height, $extraWideDesktopSize*$line-height, $superWideDesktopSize*$line-height);
}

@mixin bodyText($reductionFactor: 1){
    $wideDesktopSize: 1rem * $reductionFactor; //16px
    $mobileSize: $wideDesktopSize*0.8 * $reductionFactor; //11.2px
    $tabletSize: $mobileSize * $reductionFactor; //11.2px
    $smallDesktopSize: $wideDesktopSize*0.9 * $reductionFactor; //14.4px
    $extraWideDesktopSize: $wideDesktopSize*1.1 * $reductionFactor;//17.6px
    $superWideDesktopSize: $wideDesktopSize*1.1 * $reductionFactor; // 19.2px
    @include fluidPropertyBPs(font-size, $mobileSize, $tabletSize, $smallDesktopSize, $wideDesktopSize, $extraWideDesktopSize, $superWideDesktopSize);
    $line-height: 1.62;
    @include fluidPropertyBPs(line-height, $mobileSize*$line-height, $tabletSize*$line-height, $smallDesktopSize*$line-height, $wideDesktopSize*$line-height, $extraWideDesktopSize*$line-height, $superWideDesktopSize*$line-height);
    letter-spacing: 0.3px;
}

@mixin h5(){
    @include bodyText();
    font-family: $bodyFontStackBold;
}

@mixin h6(){
    @include bodyText();
    font-family: $bodyFontStackBold;
}

@mixin bodyTextReduced() {
    $reductionFactor: 0.85;
    @include bodyText($reductionFactor);
    font-weight: normal;
}

@mixin floatingTitle() {
    text-align: center;
    @include fluidPropertyBPs(margin-bottom, 2*$gridUnit, 3*$gridUnit, 4*$gridUnit, 5*$gridUnit, 6*$gridUnit, 7*$gridUnit);
}

@import "../../../variables.scss";

.column {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-content: center;
    background-color: $greyscaleColour6;
    min-width: 10rem;
    margin-left: 6px;
}

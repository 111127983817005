@import "../../../../../../variables.scss";
@import "../../../../SizeChip/SizeChip";

.skuSpan {
  width: $chipWidth;
  min-height: $chipHeight;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-top: 3px;
}

.inactive {
  color: $whiteColour;
}

@import "../../variables.scss";

.noHierarchySelected {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: $whiteColour;
  color: $greyscaleColour3;
  p{
    margin: 0;
    font-size: $gridUnit*3;
  }
}

@import "../../../../variables.scss";

.resultsContainer {
  height: 45*$gridUnit;
  overflow: auto;
  background-color: $whiteColour;
  .singleResult {
    &.selected {
      background-color: $greyscaleColour10;
    }
    input {
      height: 100%;
      cursor: pointer;
    }
    .styleRadio {
      padding-left: $gridUnit;
    }
    display: flex;
    border-bottom: 1px solid $greyscaleColour4;
    &:hover {
      cursor: pointer;
      background-color: $greyscaleColour10;
    }
    .styleItem {
      padding: $gridUnit 0 $gridUnit $gridUnit;
      .styleId {
        font-weight: bold;
      }
    }
  }
}

.controlButtons {
  display: flex;
  justify-content: space-between;
  padding-top: 4*$gridUnit;
  .button {
    padding: $gridUnit $gridUnit;
  } 
}
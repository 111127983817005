@import "../../../variables";

.cellValue{
  width: 100%;
}

.cell {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 3 * $gridUnit;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
  input {
    padding:5px;
    height: 100%;
    width:60%;
    border: none;
    font: inherit;
    &:hover,
    &:focus,
    &:active {
      border-color: $activeColour;
      outline: none;
    }
  }
}


.currencyDropdown {
  position: relative;
  top: 10px;
}

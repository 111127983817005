@import "../../variables.scss";

$track-w: $gridUnit * 20;
$track-h: $gridUnit * 0.125;
$thumb-d: $gridUnit * 3;

@mixin track() {
  box-sizing: border-box;
  border: none;
  width: $track-w; height: $track-h;
  background: $greyscaleColour4;
}

@mixin thumb() {
  box-sizing: border-box;
  border: none;
  width: $thumb-d; height: $thumb-d;
  border-radius: 50%;
  background: $screenBackgroundColour;
}

[type='range'] {
  &, &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  outline-width: 0;
  margin: 0;
  padding: 0;
  width: $track-w; height: $thumb-d;
  background: transparent;
  font: 1em/1 arial, sans-serif;
  border-right: $track-h solid $greyscaleColour4;
  border-left: $track-h solid $greyscaleColour4;

  &::-webkit-slider-runnable-track {
    @include track
  }

  &::-webkit-slider-thumb {
    margin-top: .5*($track-h - $thumb-d);
    @include thumb
  }

  &:hover::-webkit-slider-thumb, &.active::-webkit-slider-thumb {
    box-shadow: $baseHeavyBoxShadow;
  }
}

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: $gridUnit*9;
  align-items: center;
}

.label {
  color: $greyscaleColour3;
  margin-bottom: 0;
}

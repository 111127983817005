@import "../../variables.scss";

.logo {
  $logoWidth: $gridUnit*20;
  margin-top: -2px;
  width: $logoWidth;
  @media ($tablet){
    margin-left: $gridUnit*2.5;
  }
}

.RILogo {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

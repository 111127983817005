@import "../../../variables.scss";
@import "../../../components/StyleHelpers/BlackAndWhiteInteractivity/blackAndWhiteInteractivity";

.defaultAttributesContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.gridExternalContainer {
  height: 90%;
}

.newDefaultAttributeContainer {
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
}

.newDefaultAttributeTrigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $gridUnit*5;
  height: $gridUnit*5;
  padding: 0;
  margin-right: $gridUnit*2.5;
  border-radius: 100%;
  background: $whiteColour;
  outline: none;
  border: 2px solid $blackColour;

  &:hover,
  &:focus,
  &:active {
    @include blackAndWhiteInteractivity();
  }

  svg {
    width: 60%;
    height: 60%;
  }
}

.button {
  padding: $gridUnit $gridUnit;
  &.primary {
    margin-left: 1rem;
  }
  &.secondary {
    margin-right: 1rem;
  }
}

.modalButtons {
  display: flex;
}
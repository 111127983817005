@import "../../variables.scss";
@import "../../components/Typography/typography";

.filterDrawer{
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100vw;
  background-color: rgba(0,0,0,0.1);
  .links {
    display: flex;
    justify-content: space-between;
    margin: $gridUnit 2*$gridUnit $gridUnit 2*$gridUnit;
    align-items: center;
  }

  .search {
    display: flex;
    align-items: center;

    .filterIcon {
      width: 1.5rem;
    }
  }

}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 0 2*$gridUnit $gridUnit 2*$gridUnit;
  .exactFilterTag, .partialFilterTag {
    //margin: 0 2*$gridUnit;
    margin: 0 0.5*$gridUnit $gridUnit 0;
  }
}

.deprecatedValue {
  text-decoration: line-through;
}

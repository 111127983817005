@import "../../variables.scss";

.keyboardNavigation{
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 9999 1 auto;
  &:focus {
    outline:none;
  }
}

@import "../../../variables.scss";
.RITableTd {
  background-color: $whiteColour;
  box-sizing: border-box;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1 0 0;
  text-overflow: ellipsis;
  transition: .3s ease;
  transition-property: width, min-width, padding, opacity;
  hyphens: auto;
  border-bottom: 1px solid $greyscaleColour7;
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.RITableTdInner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  hyphens: auto;
  -ms-hyphens: auto;
  width: 100%;
  height: 100%;
  &:first-letter {
    text-transform: capitalize;
  }
}

.validation {
  position: absolute;
  top: 0;
  padding: $gridUnit;
  width: auto;
  background-color: $whiteColour;
  color: $errorColour;
  box-shadow: $baseBoxShadow;
  z-index: 3;
  text-transform: none;
  word-break: normal;
  &:first-letter {
    text-transform: capitalize;
  }
}

.suggestion {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: 2px solid $accentColour;
  z-index: 3;
}

.cellSpinner {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background: #f1f1f1;
  opacity: 0.5;
  align-items: center;
  justify-content: center;
}

.copyIconContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  .copyIcon {
    width: 20px;
    height: 20px;
  }
}

.pasteCellContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $greyscaleColour3;
}
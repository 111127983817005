@import "../../variables";

@keyframes appear {
  0% { opacity: 0;}
  99% {opacity: 0;}
  100% {opacity: 100;}
}

@keyframes spin {
  0% {transform:rotate(0deg);}
  10% {transform:rotate(0deg);}
  92% {transform:rotate(185deg);}
  100% {transform:rotate(180deg);}
}

.spinnerContainer {
  flex-direction: column;
  align-items: center;
  animation-duration: 1ms;
  animation-name: appear;
  animation-timing-function: linear;
  display: flex;
  height: calc(100vh - #{$headerDesktopHeight});
  justify-content: center;
  justify-items: center;
  @media ($mobileOnly) {
    height: calc(100vh - #{$headerMobileHeight});
  }
  @media ($tablet) {
    height: calc(100vh - #{$headerTabletHeight});
  }
}

.spinner {
    animation-duration: 1800ms;
    animation-iteration-count: infinite;
    animation-name: spin;
    animation-timing-function: ease-in;
    height: 10vh;
    width: 10vh;
}

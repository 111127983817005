@import "../../../variables.scss";

.section {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: $greyscaleColour6;
  padding: 3px 16px;
  border-bottom: 2px solid $whiteColour
}

.lastSection {
  border-bottom: 0;
}

.teenGroupBox {
  background: $greyscaleColour10;
}

.miniGroupBox {
  background: $greyscaleColour11;
}

.miniExtGroupBox {
  background: $greyscaleColour5;
}
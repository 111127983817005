@import "../../../variables";

.cellValue{
  width: 100%;
  padding: $gridUnit;
}

.deprecatedValue {
  text-decoration: line-through;
}

.dropdownWrapper {
  left: 0;
  top: 0;
  width: 100%;
  text-align: left;
  position: absolute;
}

.cell {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 3 * $gridUnit;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

@import "../../../../variables";
@import "../../../../components/Typography/typography.scss";


.modal {
  width: 58 * $gridUnit;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text {
  text-align: center;
  margin-bottom: 3*$gridUnit;
}

.confirm {
  margin-bottom: $gridUnit;
}

.description {
  @include bodyText();
  margin-bottom: 3*$gridUnit;
}

.title {
  text-align: center;
  margin-bottom: 3*$gridUnit;
}

.statusTitle {
  margin-bottom: $gridUnit*0.5;
}

.cancel {
  color: $errorColour;
}
@import "../../variables";
@import "../../components/StyleHelpers/FluidProperty/fluidProperty";

.productsPage {
  padding-bottom: 0;
}

.toggleHierarchyAndSearch {
  display: flex;
  align-items: center;
  padding-bottom: $gridUnit*3;
  padding-top: $gridUnit*3;
}

.aboveTableControlsAndTable {
  height: $controlsAndTableHeightMobile;
  @media ($tablet) {
    height: $controlsAndTableHeightTablet;
  }
  @media ($smallDesktop) {
    height: $controlsAndTableHeightSmallDesktop;
  }
  @media ($wideDesktop) {
    height: $controlsAndTableHeightWideDesktop;
  }
  @media ($extraWideDesktop) {
    height: $controlsAndTableHeightExtraWideDesktop;
  }
  @media ($superWideDesktop) {
    height: $controlsAndTableHeightSuperWideDesktop;
  }
  overflow: hidden;
}

.aboveTableControls {
  height: $aboveTableControlsHeightMobile;
  @media ($tablet) {
    height: $aboveTableControlsHeightTablet;
  }
  @media ($smallDesktop) {
    height: $aboveTableControlsHeightSmallDesktop;
  }
  @media ($wideDesktop) {
    height: $aboveTableControlsHeightWideDesktop;
  }
  @media ($extraWideDesktop) {
    height: $aboveTableControlsHeightExtraWideDesktop;
  }
  @media ($superWideDesktop) {
    height: $aboveTableControlsHeightSuperWideDesktop;
  }
  &.optionLibrary {
    background-color: $screenBackgroundColour;
  }
  &.devTracker {
    background-color: $screenBackgroundColour;
  }
}
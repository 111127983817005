@import "../../variables.scss";
@import "../../components/StyleHelpers/Accessibility/accessibility.scss";
@import "../../components/Typography/typography.scss";
@import "../../components/StyleHelpers/BlackAndWhiteInteractivity/blackAndWhiteInteractivity";

@keyframes spin {
  from {
  }
  to {
    transform: rotate(360deg);
  }
}

.columnsForTaskLinks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: $gridUnit * 2.5;
  margin-right: 3 * $gridUnit;
  padding-bottom: $gridUnit * 2;
}

.linksButtons {
  display: flex;
}

.priceFilters {
  margin-left: $gridUnit * 2.5
}

.linksList {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.linksListItem {
  display: flex;
  align-items: center;
}

.linksListItem:first-of-type {
  margin-left: 0;
}

.linksListItem a {
  border-left: 1px solid $greyscaleColour6;
}

.linksListItem:first-of-type a {
  border-top-left-radius: $baseBorderRadius * 2;
  border-bottom-left-radius: $baseBorderRadius * 2;
  border-left: none;
}

.linksListItem:last-of-type a {
  border-top-right-radius: $baseBorderRadius * 2;
  border-bottom-right-radius: $baseBorderRadius * 2;
}

.actionButtonContainer {
  display: flex;
}

.multiSelectButton {
  border-radius: $baseBorderRadius * 2;
}

.moveToButton {
  display: block;
  min-width: 9 * $gridUnit;
  text-align: center;
  opacity: 1 !important;
}

.popoverContainer {
  min-width: 22 * $gridUnit;
  top: 6 * $gridUnit;
  right: 0.02 * $gridUnit !important;
}

.popoverContainer li > a {
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}

.exportIcon,
.refreshIcon,
.importIcon {
  height: 2.5 * $gridUnit;
  width: 2.5 * $gridUnit;
  vertical-align: middle;
}

.refreshIcon {
  &:hover {
    animation: spin 1s;
  }
}

.link {
  @include bodyTextReduced();
  padding: $gridUnit;
  color: $blackColour;
  background-color: $whiteColour;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    @include blackAndWhiteInteractivity();
  }
  &::first-letter {
    text-transform: uppercase;
  }
  &.selected {
    background-color: $blackColour;
  }
}

.icon {
  padding: 0.75 * $gridUnit;
  width: 4.5 * $gridUnit;
  text-align: center;
}

.active {
  .link {
    color: $whiteColour;
    background-color: $blackColour;
  }
}

.disableClick {
  pointer-events: none;
}

.inputFile {
  display: none;
}

.importButton {
  border-top-left-radius: $baseBorderRadius * 2;
  border-bottom-left-radius: $baseBorderRadius * 2;
  border-left: none;
  margin: 0;
}

.importButton:hover {
  cursor: pointer;
}

@import "../../../variables";

.sketchImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: $gridUnit*12;
  overflow: hidden;
  margin: 0 auto;
}

.sketchImage {
  width: $gridUnit*12;
  margin-left: -($gridUnit*0.5);
  margin-right: -($gridUnit*0.5);
}

@import "../../../../variables";

.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $gridUnit * 8.625;
  border: 1px solid $greyscaleColour3;
  background-color: $greyscaleColour7;
  color: $tableHeaderColour;
  text-transform: uppercase;
  line-height: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}
.previewText{
display: block;
text-align: center;
overflow: hidden;
text-overflow: ellipsis;
word-break: normal;
}
.outerBoundary {
  display: flex;
  width: 302px;
  height: $gridUnit * 8.625;
  border: 1px dashed $greyscaleColour5;
  justify-content: center;
}
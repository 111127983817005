@import "../../../variables";

.menuOptionsContainer {
  position: absolute;
  width: 20 * $gridUnit;
  top: 0;
  right: -20 * $gridUnit;
  border-radius: 0.25rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.12);
  background-color: $whiteColour;
  border: 1px solid $greyscaleColour5;
  text-transform: none;

  .menuOptionContainer {
    line-height: 2;
    border-bottom: 1px solid $greyscaleColour5;
    &:hover {
      background-color: $greyscaleColour7;
    }
  }

  .menuOptionCheckbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0.5rem;
  }

  .menuOptionLink {
    cursor: pointer;
  }
}
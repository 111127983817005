@import "../../../../variables";

.bulkStatusChangeModal {
  flex-direction: column;
  align-items: center;
  display: flex;

  .areYouSure {
    font-size: 2 * $gridUnit;
    color: $greyscaleColour3;
  }

  .summaryMessage {
    color: $blackColour;
    font-size: 2.5 * $gridUnit;
  }

  .confirmButton {
    margin-bottom: 15px;
    display: block;
  }

  .cancelButton {
    display: block;
  }
}

@import "../../../variables";

.cellValue {
  box-sizing: content-box;
  text-transform: none;
  width: calc(100% - #{4 * $gridUnit});
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: $gridUnit;
  min-height: 100%;
  overflow: hidden;
}

.scrollWrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.textAreaWrapper {
  left: 0;
  top: 0;
  width: calc(100% - #{2.5 * $gridUnit});
  height: 100%;
  text-align: left;
  position: absolute;
  textarea {
    width: 100%;
    height: 100%;
    font-size: 1.5 * $gridUnit;
  }
  z-index: 1;
}

.cell {
  position: absolute;
  top: $gridUnit;
  padding: 0;
  display: flex;
  width: calc(100% + #{2.5 * $gridUnit});
  height: calc(100% - #{2 * $gridUnit});
  justify-content: center;
  align-items: center;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow-x: hidden;
  overflow-y: hidden;
  &.inEditMode {
    overflow-y: hidden;
    height: 100%;
    top: 0;
  }
}

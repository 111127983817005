@import "../../../../variables.scss";

.headerTitle {
  top: 0;
  position: sticky;
  align-items: center;
  background-color: $activeColour;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  color: $whiteColour;
  display: flex;
  font-family: $bodyFontStackBold;
  font-size: 12px;
  height: 2.3rem;
  justify-content: center;
  min-width: 5rem;
  text-transform: uppercase;
}

.currencyContainer {
  margin-right: .5rem;
}

@import "../../../../variables";


.linkedProductContainer {
  border-radius: 5px;
  background-color: $whiteColour;
  display: flex;
  flex-direction: row;
  min-width: 200px;
}

.linkedProduct {
  display: flex;
  flex-direction: column;
  font-size: 1.8 * $gridUnit;
  width: 96%;
  padding: $gridUnit 8px $gridUnit 0;
}

.productAndImage {
  display: flex;
  position: relative;
}

.selectedProduct {
  border: 2px solid $greyscaleColour4
}

.productInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2 * $gridUnit;
  width: 100%;
}

.column{
  padding-right: 3 * $gridUnit;
  padding-top: 0;
  &:last-child {
    padding-right: 0;
  }
  &:nth-child(2){
    width: 10 * $gridUnit;
  }
  &:first-child {
    width: 14 * $gridUnit;
  }
}

.columnTitle {
  color: $greyscaleColour34;
  font-size: 10px;
}

.value {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.xContainer {
  display: flex;
  cursor: pointer;
  margin: 0 0 0 auto;
  position: absolute;
  top: -3*0.25*$gridUnit;
  right: -3*0.25*$gridUnit;
  height: 3*$gridUnit;
  width: 3*$gridUnit;
  border-radius: 100%;
  &:focus, &:active, &:hover {
    outline: none;
    box-shadow: $baseBoxShadow;
  }
}

.imageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  padding-right: 2rem;
  width: 7rem;
}

.imageStyle {
  max-height: 50px;
  max-width: 50px;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin-bottom: $gridUnit * 6;
    max-width: 35*$gridUnit;
    text-align: center;
  }
}

.modalButton {
  margin-bottom: 0.5 * $gridUnit;
}

.x {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.linkIconContainer {
  display: flex;
  padding-left: 4px;
  width: 4%;
  &.selectedLinkIcon {
    padding-left: 6px;
  }
}

.linkIcon {
  width: 1.75 * $gridUnit;
  margin-left: 0.5rem;
}
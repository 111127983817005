@import "../../variables";

.modal {
  background-color: $successColour;
  text-align: center;
  width: 70 * $gridUnit;
  color: $whiteColour;
  font-size: $baseBodyFontSize * 1.3;
  line-height: 2 * $gridUnit;

  .buttons {

    button {
      display: inline-block;
      padding: $gridUnit*1.5 $gridUnit*2;
      margin: $gridUnit*2;
    }
  }
}

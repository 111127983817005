@import "../../../variables.scss";

@mixin panel($borderRadius: $baseBorderRadius, $transparency: false) {
    $backgroundColor: $whiteColour;
    @if($transparency == true){
        $backgroundColor: rgba(255, 255, 255, 0.5);
    }
    padding: $gridUnit * 3;
    border: 1px solid $greyscaleColour6;
    box-shadow: $baseBoxShadow;
    border-radius: $borderRadius;
    background-color: $backgroundColor;
}
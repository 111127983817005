@import "../../../../variables";
@import "../../../../components/Typography/typography";

.tagContainer {
  width: 100%;
  background-color: $greyscaleColour7;
  padding: 2px 2px;
  height: 100%;
  max-height: 13.13*$gridUnit;
  overflow-y: auto;
  display: -ms-flexbox;
  display: flex;
  flex-flow: wrap;
  margin-top: 16px;
}

.container {
  width: 100%;
}

.readOnlyTag {
  @include bodyTextReduced();
  border-radius: $baseBorderRadius;
  margin: $gridUnit*0.25;
  color: $blackColour;

  padding: 0.5*$gridUnit;
  background-color: $greyscaleColour6;
  border: 1px solid $greyscaleColour4;
}
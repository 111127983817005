@import "../../variables.scss";

.avatar {
  width: 5 * $gridUnit;
  padding-left: 0.75 * $gridUnit;
  padding-bottom: 0.5 * $gridUnit;
  vertical-align: middle;
}

.chevronDown {
  width: 2.5 * $gridUnit;
  padding-left: 0.75 * $gridUnit;
  padding-bottom: 0.25 * $gridUnit;
}

.exportIcon,
.refreshIcon {
  height: 2.5 * $gridUnit;
  width: 2.5 * $gridUnit;
}

.ellipses {
  border-radius: 100%;
  &:hover,
  &:focus,
  &:active {
    box-shadow: $baseHeavyBoxShadow;
  }
}

.defaultStroke {
  stroke: $blackColour;
}

.defaultFill {
  fill: $blackColour;
}

.disabledFill {
  fill: $greyscaleColour4;
}

.disabledStroke {
  stroke: $greyscaleColour4;
}

.riIcon {
  fill: #252525;
}

.colourLinkIcon {
  fill: $colourLinkColour;
}

.supplierLinkIcon{
  fill: $supplierLinkColour;
}

.matchingProductLinkIcon{
  fill: $matchingProductLinkColour;
}

.crossDepartmentLinkIcon{
  fill: $crossDepartmentLinkColour;
}

.reloadIcon {
  width: 2.5 * $gridUnit;
  height: 2 * $gridUnit;
  fill: $greyscaleColour3;
}

.trashIcon {
  width: 2 * $gridUnit;
  height: 2.5 * $gridUnit;
  fill: $greyscaleColour3;
}

.christmasLogo {
  $logoWidth: $gridUnit * 20;
  margin-top: -14px;
  width: $logoWidth;
  @media ($tablet) {
    margin-left: $gridUnit * 2.5;
  }
}

.riChristmas {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

@import "../../../../../../../variables";
@import "../../../../../../Typography/typography";


.wrapper {
  display: flex;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 0 2*$gridUnit;
  width: 18*$gridUnit;
}

.title, .status {
  @include bodyTextReduced();
  &::first-letter {
    text-transform: uppercase;
  }
}

.title {
  font-family: $bodyFontStackBold;
  color: $blackColour;
}

.status {
  color: $blackColour;
}



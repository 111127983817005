@import "../../../components/Typography/typography";

.filterWrapper {
  display: flex;
  padding: 0 .5rem 0 .5rem;
}

.filterDropdown {
  width: 13rem;
  margin-right: 0.5rem;
}

.clearAll {
  border-radius: 1.6rem;
  padding: 0 0.6rem;
  @include bodyTextReduced();
  font-size: 0.578rem;

  display: flex;
  align-items: center;
  background-color: #095577;

  a {
    text-decoration: none;
    color: #fff;
    outline: none;

  }

  &:hover {
    background-color: $blackColour;
  }

  &:focus, :active {
    background-color: $blackColour;
  }


}
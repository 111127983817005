@import "../../variables.scss";
@import "../StyleHelpers/Ripple/ripple.scss";
@import "../../components/Typography/typography.scss";

$utilityNavDesktopHeight: $headerDesktopHeight;
$utilityNavMobileHeight: $headerMobileHeight;

.utilityNavigation {
  position: absolute;
  right: 0;
  display: flex;
  margin-right: $gridUnit;
  @supports (display: grid) {
    justify-self: end;
    position: static;
    right: unset;
  }
}

.list {
  display: flex;
  align-items: center;
  padding: 0;
}

.menu {
  &:focus {
    outline: none;
  }
}

.popoverContainer {
  min-width: 25*$gridUnit;
}

.link {
  padding: 0 $gridUnit*0.5;
  position: relative;
  display: flex;
  align-items: center;
  height: $utilityNavDesktopHeight;
  @include bodyText();
  margin: 0 $gridUnit*0.5;
  white-space: nowrap;
  text-decoration: none;
  color: $whiteColour;
  position: relative;
  overflow: hidden;
  @include h4();
  @include ripple;
  @media ($mobileOnly) {
    height: $utilityNavMobileHeight;
  }
}
.link.linkActive {
  box-shadow: inset 0 -4px $accentColour;
}

.triggerLink {
  @media($mobileOnly) {
    display: none;
  }
}

.span {
  padding: $gridUnit;
}

.help {
  padding-right: 15px;
  @media($mobileOnly) {
    display: none;
  }
}

.loginButton {
  min-width: 6rem;
  padding: $gridUnit;
  @media  ($mobileOnly){
    transform: scale(0.7);
  }
}

.linkActive .span {
  text-shadow: 0 0 2px #ffffff;
}

.menuPosition {
  right: 0;
}

.closeLink {
  text-align: right;
}

@import "../../variables.scss";

.styleModalContainer {
  background-color: $greyscaleColour12;
  width: 45%;
  height: 86%;
  overflow: auto;
  .modalBody {
    height: 70*$gridUnit;
  }

  .tabs {
    margin-top: 4*$gridUnit;
    height: 7*$gridUnit;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    border-bottom: 1px solid $greyscaleColour3;
  }

  .tab {
    font-family: $bodyFontStackRegular;
    color: $greyscaleColour2;
    background-color: $greyscaleColour8;
    width: 220px;
    text-align: center;
    vertical-align: middle;
    line-height: 8*$gridUnit;
    position: center;
    text-decoration: none;
    flex-grow: 1;

    &:focus {
      outline: none;
    }
  }

  .activeTab {
    border-bottom: 4px solid $activeColour;
  }

  .viewContainer {
    margin-top: 2*$gridUnit;
  }

  .tabDescription {
    padding-top: 2*$gridUnit;
  }

  .loadingContent {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@import "../../variables.scss";
@import "../../components/StyleHelpers/Background/background.scss";
@import "../../components/SecondaryButton/secondaryButton.scss";
@import "../../components/PrimaryButton/primaryButton.scss";
@import "../../components/Typography/typography.scss";
@import "../../components/StyleHelpers/PageGrid/pageGrid.scss";
@import "../../components/StyleHelpers/Panel/panel.scss";

.newProductPage {
  @include background(style1);
  min-height: 100vh;
}

.pageGrid {
  @include pageGrid($extraClearanceForDropdowns: true);
  align-items: flex-start;
  justify-content: center;
}

.floatingTitle {
  @include floatingTitle;
}

.panelAndSketch {
  @media ($tablet){
    display: flex;
    position: relative;
    width: 40vw;
    margin: 0 auto;
  }
}

.panel {
  @include panel();
  width: 100%;
  @media ($mobileOnly) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

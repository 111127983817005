@import "../../../../variables";
@import "../../../../components/Typography/typography";

.commentCountSection {
  margin-top: 0.25*$gridUnit;
  font-weight: 600;
  color: $blackColour;
  @include bodyText(0.8);
}

.developmentIdCell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.commentIndicator{
  width: 2.5 * $gridUnit;
  margin-top: 3px;
  vertical-align: middle;
  display: inline-block;
}

.iconWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.linkIcon{
  width: 1.75 * $gridUnit;
  margin-right: 0.30*$gridUnit;
  margin-top: 3px;
}

.priceTagIcon {
  width: 16px;
  height: 16px
}
@import "../../variables.scss";

$adminHeaderHeight: 10*$gridUnit;

.userSettingsPage {
    background-color: #ffffff;
    height: calc(100vh - #{$headerDesktopHeight});
    color: #959595;
  }
  
.userSettingsBody {
  display: flex;
  height: calc(100vh - #{$headerDesktopHeight});
}

.spinnerContainer {
  height: calc(100vh - #{$headerDesktopHeight * 2.2});
}
@import "../../../../variables";

.linksGroup {
  padding-top: 2 * $gridUnit;
  padding-bottom: 2 * $gridUnit;
}

.heading {
  font-size: 2 * $gridUnit;
}

.listItem {
  margin-bottom: 2 * $gridUnit;
  &:last-child {
     margin-bottom: 0;
   }
}
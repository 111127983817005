@import "../../variables";

.checkboxChecked, .checkboxNotChecked {
  width: 3.5*$gridUnit;
  height: 3.5*$gridUnit;
  border-radius: 100%;
  &.addBorder {
    border: 2px solid $whiteColour
  }
  &:hover, &.active {
    box-shadow: $baseHeavyBoxShadow;
  }
}

.checkboxNotChecked {
  &:hover, &.active {
   border: 3px solid $greyscaleColour4;
  }
}

.checkBoxLink {
  width: 3.5*$gridUnit;
  height: 3.5*$gridUnit;
}

.checkBoxLink:focus {
  outline: none
}

.checkboxDisabled {
  cursor: default;
  opacity: 0.4;
  &:hover, &.active {
    box-shadow: none;
    width: 3.5*$gridUnit;
    height: 3.5*$gridUnit;
    border: none
  }
}

.checkBoxLinkDisabled {
  cursor: default;
}

@import "../../../../variables";

.actionCell {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.spinnerContainer {
    opacity: 0.4;
    display: flex;
    align-items: center;
}

.hasError {
    background-color: $accentColour;
}

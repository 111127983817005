@import "../../../../../variables.scss";
@import "../../../../Typography/typography.scss";

.seasonToolContainer {
  background-color: $greyscaleColour7;
  display: flex;
  padding: 2rem;
  width: 100%;
  max-height: 800px;
}

.header {
  display: flex;
  flex-direction: column;
}

.title {
  color: $blackColour;
}

.subtitle {
  margin-top: -25px;
  font-size: small;
}

.seasonsContainer {
  width: 100%;
  background-color: $whiteColour;
  border-radius: .5rem;
  overflow-x: auto;
  padding: 1rem;
}

.seasonsGrid {
  width: 800px;
  display: grid;
  grid-gap: 1rem 2rem;
  grid-template-columns: auto;
  grid-template-rows: repeat(10, 1fr);
  grid-auto-flow: column;
  justify-items: center;
}

.current {
  color: $screenBackgroundColour;
  font-weight: bold;
}
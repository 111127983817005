@import '../../../../src/variables.scss';

.commsModalContainer{
    background-color: $greyscaleColour7;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 90%;
    width: fit-content;
    padding: 15px 0px;
}
.commsModalContainerNoFile{
    background-color: $greyscaleColour7;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 90%;
    width: 35%;
    padding: 15px 0px;
}
.documentContainer{
    overflow-y: scroll;
}
.exitButtonContainer{
    display: flex;
    justify-content: flex-end;
    background-color: $greyscaleColour7;

}
.markAsReadButtonContainer{
    text-align: right;
    background-color: $greyscaleColour7;

}
.exitModalButton{
    color: $blackColour;
    border: none;
    border-radius: 50%;
    margin-right: 2 * $gridUnit;
    margin-bottom: 2 * $gridUnit;
    box-shadow: $baseBoxShadow;
    height: 3.5 * $gridUnit;
    width:  3.5 * $gridUnit;
    font-size: 1rem;
    background-color: $whiteColour;
    display: flex;
    justify-content: center;
    align-items: center;
}
.exitModalButton:hover{
    background-color: $greyscaleColour4;
}

.markAsReadButton{
    margin: 2 * $gridUnit;
}

@import "../../variables.scss";

.container{
  overflow-y: scroll;
  height: 100%;
  flex: 0 0 auto;
  width: 42*$gridUnit;
}

.draggableList li {
  padding: 1.5 * $gridUnit 0;
  border-bottom: 1px solid rgb(238, 240, 239);
  &:hover, &:focus, &:active {
    color: $activeColour;
    background-color: $greyscaleColour5;
  }
}

.draggableIcon {
  fill: $greyscaleColour3;
  height: 100%;
  opacity: 0;
  vertical-align: middle;
  width: 4*$gridUnit; //5rem
}

.draggableList li:hover .draggableIcon{
  opacity:1;
}

.draggableList {
  position: relative;
}

.draggableList::after {
  position: absolute;
  top: calc((32px + 24px + 1px) * 6 - 1px);
  display: block;
  content: "";
  width: 100%;
  height: 2px;
  background-color: rgb(221, 221, 221);
}

@import "../../../../variables.scss";

.availableSizesColumn {
  background-color: $greyscaleColour6;
  height: 100%;
  width: 10rem;
}

.availableSizesTitle {
  background-color: #4f6669;
  position: sticky;
  top: 0;
}

.noSizes {
  text-align: center;
  color: $greyscaleColour3;
}

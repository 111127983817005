@import "../../variables.scss";

.notAvailable{
    margin: 20px;
}

.spinnerContainer {
    width: 100%;
}

.userSettingContainer {
    background-color: $greyscaleColour7;
    display: flex;
    padding: 2rem;
    width: 100%;
    overflow: hidden;
}

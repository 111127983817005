@import "../../../variables.scss";

.link {
  padding: 0.5 * $gridUnit;
  &:focus, &.active {
    outline: none;
  }
}

.dot {
  height: 100%;
  margin-bottom: $gridUnit;
  margin-top: $gridUnit;
  vertical-align: middle;
  width:30px !important;
}



@import "../../variables";

.carouselContainer {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  &:focus {
    outline: none
  }
}

.imageAndArrowsContainer, .imageContainer {
   display: flex;
   align-items: center;
   justify-content: space-between;
   width: 95%;
   height: 80%;
 }

.imageAndArrowsContainer {
  justify-content: space-between;
}

.imageContainer {
  justify-content: center;
  align-items: center;
}

.arrowLink {
  &:focus, &.active {
    outline: none;
    .arrow {
      color: #959595 !important;
    }
  }
}

.arrow {
  color: #dddddd !important;
  &:hover, &:focus {
    color: #959595 !important;
  }
}

.image {
  max-height: 100%;
  max-width: 85%;
  align-self: baseline;
}

.camera {
  width: 15*$gridUnit;
  height: 25*$gridUnit;
}
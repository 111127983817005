@import "../../../../../../variables";

.errorMenuList {
  flex: 1;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  justify-items: stretch;
}

.errorMenuListItem {
  flex: 1;
  display: flex;
  position: relative;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $greyscaleColour5;
  padding: 0.5 * $gridUnit $gridUnit;
  text-align: center;
  color: $greyscaleColour2;
  text-transform: none;
  background-color: $accentColour;

  &:last-of-type {
    border-bottom: none;
  }
  &:focus {
    outline: none;
  }

  .errorMenuTitle {
    max-width: 80%;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .closeButton {
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 100%;
    background: white;
    padding: 0;
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
  
    &:focus {
      outline: none;
    }
  }
}

@import "../../../../variables.scss";
@import "../../../../components/PrimaryButton/primaryButton.scss";


$spacing: 3 * $gridUnit;
.wrapper {
  width: 100%;
  .confirmSketch {
    margin-bottom: 2*$gridUnit;
    width: 26*$gridUnit;
    @media ($mobileOnly) {
      width: 9rem;
      padding: 1rem 0;
    }
  }
}

.sketchSection {
  padding: 0 $spacing $spacing $spacing;
  border-bottom: 2px solid $greyscaleColour8;
}

.buttonSection {
  background-color: white;
  padding-top: $spacing;
  @media ($mobileOnly) {
    display: flex;
    justify-content: space-around;
  }
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  border-width: 2px;
  border-radius: 2px;
  border-color: $greyscaleColour3;
  border-style: dashed;
  background-color: $whiteColour;
  color: $greyscaleColour3;
  outline: none;
  transition: border .24s ease-in-out;
  margin: 2*$gridUnit;
  &:hover {
    cursor: pointer;
    background-color: $greyscaleColour7;
  }
  &:focus {
    border-color: $activeColour;
  }

  &.disabledDropzone {
    cursor: default;
    border-color: $greyscaleColour6;
    color: $greyscaleColour6;
  }
}

@import "../../variables";

.uploadSketch {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.background {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  background-color: $greyscaleColour5;
  height: 50*$gridUnit;
  width: 100%;
  padding: $gridUnit $gridUnit;
  @media ($mobileOnly) {
    margin: 0 auto;
    height: 30*$gridUnit;
    width: 30*$gridUnit;
  }
}

.camera {
  width: 15*$gridUnit;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.spinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 8* #{$gridUnit});
}

@import "../../../variables";

.cell {
    padding: 0;
    display: flex;
    flex: 1;
    width: calc(100%);
    height: calc(100%);
    justify-content: center;
    align-items: stretch;
    text-align: center;
    word-wrap: break-word;
    &.inEditMode {
        overflow-y: hidden;
        height: 100%;
        top: 0;
    }

    a {
        align-self: center;
    }
}

.datePickerCellRenderer {
  position: absolute;
  left: 0;
  hr {
    display: none;
  }
  input {
    text-align: center;
    cursor: pointer !important;
  }
}

.cellValue {
  text-transform: none;
}

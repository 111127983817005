@import "../../../../variables";

.modalWrapper {
  background-color: $accentColour;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  line-height: 1.6;
  padding: 24px;
}

.closeButton {
  align-self: flex-end;
  height: 24px;
  width: 24px;
  padding: 0;
  border: unset;
  background-color: transparent;
  &:focus, &:active {
    outline: none;
  }
}

.overlay {
  background-color: transparent;
}

.message {
  margin-bottom: 40px;
  p {
    margin-bottom: 12px;
  }
}

.link {
  text-decoration: unset;
  &:focus, &:active {
    outline: none;
  }
}

.linkText {
  font-size: 20px;
  color: black;
  text-decoration: underline;
}

.downloadIcon {
  height: 4 * $gridUnit;
  width: 4 * $gridUnit;
}
@import "../../variables.scss";
@import "../../components/Typography/typography.scss";

.textField {
  display: inline-block;
  width: 100%;
  flex-direction: column;
}

.textField * {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.textField label {
  color: $greyscaleColour3;
  margin-bottom: 0;
}

.input {
  padding: $gridUnit*0.5 $gridUnit*1.5;
  border: 1px solid $greyscaleColour7;
  &::placeholder {
    color: $greyscaleColour34
  }
}

.small {
  @include bodyTextReduced();
  min-height: $gridUnit*5;
}

.medium {
  @include bodyText();
  min-height: $gridUnit*6;
}


.input:hover,
.input:focus,
.input:active {
  border-color: $activeColour;
  outline: none;
}

.input.input_hasError {
  border-color: $errorColour;
}

.input.input_hasError:hover,
.input.input_hasError:focus,
.input.input_hasError:active {
  border-color: $activeColour;
}
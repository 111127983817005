/* PUBLIC VARIABLES: */
/*********************/

/* OLD RI STYLEGUIDE COLOUR PALETTE */
// See: https://company-97214.frontify.com/d/T7AmIj31s6eq/style-guide#/colour-palettes
//$white-default: rgb(255,255,255); // Default
//$white-hover-state: rgb(229,229,229); // Hover state
//$lightest-grey-default: rgb(237,238,238); // Default
//$lightest-grey-hover-state: rgb(212,213,213); // Hover state
//$lightest-grey-inactive-state: rgb(245,246,246); // Inactive state
//$middle-grey-default: rgb(225,225,225); // Default
//$middle-grey-hover-state: rgb(202,202,202); // Hover state
//$middle-grey-inactive-state: rgb(239,239,239); // Inactive state
//$black-grey-default: rgb(37,37,37); // Default
//$black-grey-hover-state: rgb(51,51,51); // Hover state
//$black-grey-inactive-state: rgb(135,135,135); // Inactive state
//$black-default: rgb(0,0,0); // Default
//$black-hover-state: rgb(51,51,51); // Hover state
//$black-inactive-state: rgb(115,115,115); // Inactive state
//$active-default: rgb(65,185,122); // Default
//$active-hover-state: rgb(58,166,109); // Hover state
//$active-inactive-state: rgb(151,217,182); // Inactive state
//$links-default: rgb(73,144,226); // Default
//$links-hover-state: rgb(65,129,202); // Hover state
//$links-inactive-state: rgb(155,194,239); // Inactive state
//$transactional-default: rgb(228,231,38); // Default
//$transactional-hover-state: rgb(203,208,0); // Hover state
//$transactional-inactive-state: rgb(240,242,136); // Inactive state
//$error-default: rgb(255,88,109); // Default
//$error-hover-state: rgb(229,79,97); // Hover state
//$error-inactive-state: rgb(255,163,175); // Inactive state

/* GENERIC COLOUR PALETTE */
$blackColour: #000;
$greyscaleColour1: #222;
$greyscaleColour2: #383939;
$greyscaleColour3: #959595;
$greyscaleColour34: #a3a3a3;
$greyscaleColour4: #c7c7c7;
$greyscaleColour45: #8c8c8c;
$greyscaleColour5: #ddd;
$greyscaleColour6: #e6e6e6;
$greyscaleColour7: #eef0ef;
$greyscaleColour8: #f1f1f1;
$greyscaleColour9: #fbfbfb;
$greyscaleColour10: #dddbdb;
$greyscaleColour11: #d0d0d0;
$greyscaleColour12: #f0f0f0;
$whiteColour: #fff;

/* MEANINGFUL COLOURS (usability) */
/*$activeColour: #71B8ff;*/
$activeColour: #26b3ad; // consider changing to RI colour: #4990E2;
$activeColour20: #e3f1ff;
$accentColour: #e4e726;
$accentAccentColour: #bdbf1b;
$successColour: #41b97a;
$errorColour: #ff586d;
$colourLinkColour: #F5A623;
$supplierLinkColour: #01505B;
$matchingProductLinkColour: #05E3FF;
$crossDepartmentLinkColour: #BD10E0;

/* OTHER COLOURS */
$bodyTextColour: $greyscaleColour1;
$tableHeaderColour: #758299;

/* Playing with these colours */
$screenBackgroundColour: #00bcd4;

$secondaryBackgroundColour: #ff6e00;

/* GRID */
$gridUnit: 0.5rem;
$gridMaximum: 60rem;
$gridMargin: 1rem;
$gridPadding: 1rem;

/* COMMON */
$baseZIndex: 1000;
$bodyFontStackRegular: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
$bodyFontStackBold: "Proxima Nova Bold", "Helvetica Neue", Helvetica, Arial, sans-serif;
$bodyFontStackLight: "Proxima Nova Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
$baseBodyFontSize: 1rem; /* Default text size on smallDesktop is 16px = 1rem ~ 0.8rem + 0.2vw (see typography.css) */
$baseBodyLineHeight: 1.5;
$baseBorderRadius: 0.25rem;
$baseBoxShadow: rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
$topBoxShadow: rgba(0, 0, 0, 0.12) 0px 0px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
$baseHeavyBoxShadow: 0 3px 15px 0 rgba(0, 0, 0, 0.3);

$candyStripeBackground: repeating-linear-gradient(
    -55deg,
    transparent,
    transparent 10px,
    rgba(255, 182, 193, 0.21) 10px,
    rgba(255, 182, 193, 0.21) 20px
);

/* RESPONSIVE TYPOGRAPHY AND BREAKPOINTS */
/* Using em because see: https://zellwk.com/blog/media-query-units/ */
/* TYPOGRAPHY (used in typography.css) */
$_tabletWidth: 41.75rem; /* =668px >667 which is iPhone 6 in landscape */
$_smallDesktopWidth: 60rem; /* =960px Note: should be same as gridMaximum. Covers eg 1024x1024 old desktop monitors used by Buyers */
$_wideDesktopWidth: 81.25rem; /* =1300px Eg old but wider newer desktop monitors used by Buyers / Merchandisers */
$_extraWideDesktopWidth: 160rem; /* =2560px Eg laptops with retina screens */
$_superWideDesktopWidth: 256rem; /* =4096px Eg iMac desktops used by Designers */
/* BREAKPOINTS */
/* Mobile is just *no* breakpoint (mobile first) */
$tablet: #{"min-width: #{$_tabletWidth}"};
$smallDesktop: #{"min-width: #{$_smallDesktopWidth}"};
$wideDesktop: #{"min-width: #{$_wideDesktopWidth}"};
$extraWideDesktop: #{"min-width: #{$_extraWideDesktopWidth}"};
$superWideDesktop: #{"min-width: #{$_superWideDesktopWidth}"};
/* Shouldn't usually need mobile-only...put mobile styles in first then override them using a breakpoint */
$mobileOnly: #{"max-width: #{$_tabletWidth}"}; /* =668px - 667 is iPhone 6 in landscape */

/* PAGE LAYOUT RELATED */
// Sizes needed across modules for page layout (e.g. removing desktop height from viewport height)
$headerDesktopHeight: $gridUnit * 9;
$headerTabletHeight: $gridUnit * 9;
$headerMobileHeight: $gridUnit * 5.3;
/* TABLE LAYOUT RELATED */
// (For calculating a fixed table height to show scrollbars correctly)

// Controls height
$aboveTableControlsHeightMobile: 16.5 * $gridUnit; //88 + 44
$aboveTableControlsHeightTablet: 16.375 * $gridUnit; //88 + 43
$aboveTableControlsHeightSmallDesktop: 16.675 * $gridUnit; // 88 + 45
$aboveTableControlsHeightWideDesktop: 17.25 * $gridUnit; // 88 + 50
$aboveTableControlsHeightExtraWideDesktop: 17.25 * $gridUnit; // 88 + 50
$aboveTableControlsHeightSuperWideDesktop: 17.25 * $gridUnit; // 88 + 50

// Controls and table height
$controlsAndTableHeightMobile: calc(100vh - #{$headerMobileHeight});
$controlsAndTableHeightTablet: calc(100vh - #{$headerDesktopHeight});
$controlsAndTableHeightSmallDesktop: calc(100vh - #{$headerDesktopHeight});
$controlsAndTableHeightWideDesktop: calc(100vh - #{$headerDesktopHeight});
$controlsAndTableHeightExtraWideDesktop: calc(100vh - #{$headerDesktopHeight});
$controlsAndTableHeightSuperWideDesktop: calc(100vh - #{$headerDesktopHeight});

// Estimated scrollbar size
$scrollBarWidth: 2 * $gridUnit; // Space for scrollbar - needed for Chrome and Edge on Windows

// Table header height
$tableHeaderHeightMobile: 6 * $gridUnit;
$tableHeaderHeightTablet: 8 * $gridUnit;
$tableHeaderHeightSmallDesktop: 8 * $gridUnit;
$tableHeaderHeightWideDesktop: 8 * $gridUnit;
$tableHeaderHeightExtraWideDesktop: 8 * $gridUnit;
$tableHeaderHeightSuperWideDesktop: 8 * $gridUnit;

// Table body height
$tableBodyHeightMobile: calc(
    100vh - #{$aboveTableControlsHeightMobile + $tableHeaderHeightMobile + $headerMobileHeight + $scrollBarWidth}
);
$tableBodyHeightTablet: calc(
    100vh - #{$aboveTableControlsHeightTablet + $tableHeaderHeightTablet + $headerDesktopHeight + $scrollBarWidth}
);
$tableBodyHeightSmallDesktop: calc(
    100vh - #{$aboveTableControlsHeightSmallDesktop + $tableHeaderHeightSmallDesktop + $headerDesktopHeight +
        $scrollBarWidth}
);
$tableBodyHeightWideDesktop: calc(
    100vh - #{$aboveTableControlsHeightWideDesktop + $tableHeaderHeightWideDesktop + $headerDesktopHeight +
        $scrollBarWidth}
);
$tableBodyHeightExtraWideDesktop: calc(
    100vh - #{$aboveTableControlsHeightExtraWideDesktop + $tableHeaderHeightExtraWideDesktop + $headerDesktopHeight +
        $scrollBarWidth}
);
$tableBodyHeightSuperWideDesktop: calc(
    100vh - #{$aboveTableControlsHeightSuperWideDesktop + $tableHeaderHeightSuperWideDesktop + $headerDesktopHeight +
        $scrollBarWidth}
);

@import "../../variables.scss";
@import "../../components/Typography/typography.scss";

.textAreaWrapper {
  display: inline-block;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-bottom: $gridUnit*1.5;

  textarea {
    @include bodyText();
    padding: $gridUnit*0.5 $gridUnit*1.5;
    min-height: $gridUnit*6;
    border: 1px solid $greyscaleColour7;
    width: 100%;
    height: 100%;
    resize: none;
    margin-top: 0;
  }

  textarea:hover,
  textarea:focus,
  textarea:active {
    border-color: $activeColour;
    outline: none;
  }

  .textAreaHasError {
    border-color: $errorColour;
  }

  textarea.textAreaHasError:hover,
  textarea.textAreaHasError:focus,
  textarea.textAreaHasError:active {
    border-color: $activeColour;
  }
}

.textAreaWrapper * {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.textAreaWrapper label {
  color: $greyscaleColour3;
  margin-bottom: 0;
}

.validationMessage {
  position: fixed;
  width: auto;
  min-width: 18 * $gridUnit;
  padding: $gridUnit;
  background-color: $whiteColour;
  color: $errorColour;
  box-shadow: $baseBoxShadow;
  word-break: normal;
  hyphens: none;
  &::first-letter {
    text-transform: uppercase;
  }
}

@import "../../../../../variables";

.row {
    display: flex;
    flex-direction: row;
    align-content: center;
}

.defaultGroupRow{
    background: $greyscaleColour6;
}

.teenGroupRow {
    background: $greyscaleColour10;
}

.miniGroupRow {
    background: $greyscaleColour11;
}
@import "../../variables.scss";

.textField {
  display: inline-block;
  width: 100%;
  margin-bottom: $gridUnit * 2.5;
}

.textField * {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.textField label {
  color: $greyscaleColour3;
  margin-bottom: 0;
}

.textField input {
  padding: $gridUnit*2 $gridUnit*1.5 $gridUnit*2 $gridUnit*5;
  min-height: $gridUnit*6;
  border: 1px solid $greyscaleColour7;
  &:hover, &:focus, &:active {
    cursor: pointer;
    border-color: $activeColour;
    color: $activeColour;
  }
}

.hasError input {
  border-color: $errorColour;
}

.validationMessage {
  display: inline-block;
  width: auto;
  padding: $gridUnit;
  background-color: $whiteColour;
  color: $errorColour;
  box-shadow: $baseBoxShadow;
}

.inputWrapper {
  position: relative;
  .inputIcon {
    position: absolute;
    top: $gridUnit*1.45;
    left: $gridUnit*1.5;
    width: 3*$gridUnit;
    height: 3*$gridUnit;
    pointer-events: none;
    path {
      fill: $whiteColour;
      stroke: $greyscaleColour3;
    }
  }
  &:hover .inputIcon path {
    stroke: $activeColour;
  }

}

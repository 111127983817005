@import "../../../../variables";
@import "../../../../components/Typography/typography.scss";

$triangleWidth: 2*$gridUnit;
$borderWidth: 3px;
$backTriangleWidth: calc(#{$triangleWidth} + #{$borderWidth});

.comment, .userComment {
  @include bodyTextReduced();
  padding: $gridUnit;
  position: relative;
  width: 420px;
  background: $whiteColour;
  border-radius: 0.5*$gridUnit;
  margin-bottom: $gridUnit;
  p {
    margin-bottom: 0;
    white-space: pre-wrap;
    hyphens: auto;
  }
  .dateTime {
    @include bodyText(0.7);
    margin-bottom: 0.75*$gridUnit;
  }
  .header {
    @include bodyText(0.8);
    font-weight: 600;
  }
  &:before, &:after {
    content: '';
    position: absolute;
    border-style: solid;
    display: block;
    width: 0;
  }
}

.comment {
  left: -5px;
  border-top-left-radius: 0;
  border: $greyscaleColour3 solid $borderWidth;

  &:before {
    border-width: 0 $backTriangleWidth $backTriangleWidth 0;
    border-color: transparent $greyscaleColour3 transparent transparent;
    z-index: 0;
    left: calc(-#{$triangleWidth} - 2*#{$borderWidth});
    top: -$borderWidth;
  }
  &:after {
    border-width: 0 $triangleWidth $triangleWidth 0;
    border-color: transparent $whiteColour transparent transparent;
    z-index: 1;
    left: calc(-#{$triangleWidth} + 1px);
    top: 0;
  }
}

.userComment {
  left: 5px;
  border-top-right-radius: 0;
  border: $activeColour solid $borderWidth;
  &:before {
    border-width: $backTriangleWidth $backTriangleWidth 0 0;
    border-color: $activeColour transparent transparent transparent;
    z-index: 0;
    right: calc(-#{$triangleWidth} - 2*#{$borderWidth});
    top: -$borderWidth;
  }
  &:after {
    border-width: $triangleWidth $triangleWidth 0 0;
    border-color: $whiteColour transparent transparent transparent;
    z-index: 1;
    right: calc(-#{$triangleWidth} + 1px);
    top: 0;
  }
}
@import "../../variables.scss";

.container {
  display: flex;
  align-items: flex-end;
}

.button {
  margin-left: $gridUnit;
  height: 5*$gridUnit;
  margin-bottom: 3*$gridUnit;
  padding-top: 0;
  padding-bottom: 0;
}

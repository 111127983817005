@import "../../variables.scss";

.hideInput {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  top: 0;
  left: 0;
}

.defaultRadioButtonClass {
  margin-top: $gridUnit*0.25;
  position: absolute;
  top: 0;
  left: 0;
  height: 2.75*$gridUnit;
  width: 2.75*$gridUnit;
  background-color: $whiteColour;
  border: 3px solid $greyscaleColour4;
  cursor: pointer;
  border-radius: 50%;
  &:hover, &:focus {
    box-shadow: $baseBoxShadow;
    border: 3px solid $greyscaleColour3;
  };
  &:focus {
    outline: unset;
  }
}

.defaultOptionClass {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  user-select: none;
  input:checked ~ .defaultRadioButtonClass {
    background-color: $activeColour;
  }
  &.containerSelectable {
    cursor: pointer;
  }
}

.defaultLabelClass {

}
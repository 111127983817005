@import "../../../../../variables.scss";
@import "../../../../../components/Typography/typography.scss";


.title {
  @include bodyText(0.9);
  line-height: 2;
  display: flex;
  justify-content: space-between;
  margin-bottom: $gridUnit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tag{
  margin-bottom: 0.5 * $gridUnit;
  padding-right: 0;
  &:last-child {
    margin-bottom: 0;
  }
}

.textField {
  margin-bottom: $gridUnit*1.5;
}

.icon {
  width: 3.5 * $gridUnit
}

.container {
  cursor: default;
  &:focus {
    outline: none;
  }
}

.deprecatedValue {
  text-decoration: line-through;
}
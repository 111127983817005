@import "../../../../variables.scss";

.wsImageContainer {
  width: 180px;
  @media only screen and ($mobileOnly) {
    width: 260px;
  }
  padding-top: 12px;
  background-color: $whiteColour;
  border-radius: 4px;
  text-align: center;
  
  &.highlightWsImageContainer {
    border: 2px solid $screenBackgroundColour;
  }
}

.wsImage {
  margin: auto;
  @media only screen and ($mobileOnly) {
    width: 240px;
    height: 240px;
  }
  width: 160px;
  height: 160px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $blackColour;
  cursor: grab;
}

.wsImagePlaceholder {
  @media only screen and ($mobileOnly) {
    width: 240px;
    height: 240px;
  }
}

.editButtonsContainer {
  @media only screen and ($mobileOnly) {
    width: 240px;
  }
  width: 160px;
  margin: auto;
  height: 36px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .editButtonIcon {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    &.replaceIconContainer {
      &:hover {
        background-color: $screenBackgroundColour;
        svg {
          fill: $whiteColour
        }
      }
    }
    &.removeIconContainer {
      &:hover {
        background-color: $secondaryBackgroundColour;
        svg {
          fill: $whiteColour
        }
      }
    }
  }
  .editButtonsSeparator {
    width: 1px;
    height: 100%;
    background-color: $greyscaleColour3;
  }
}


@import "../../../../../variables.scss";

.list {
  overflow-y: scroll;
}

.departmentAttributeListItem {
  min-width: 30*$gridUnit;
  background-color: white;
  margin: $gridUnit;
  border-radius: $baseBorderRadius;
  &:hover, &:focus, &:active {
    box-shadow: $baseHeavyBoxShadow;
    outline: unset;
  }
  &:focus, &:active, &:focus-within {
    border: 1px solid $activeColour;
  }
  &:last-child {
    margin-bottom: 5*$gridUnit;
  }
}
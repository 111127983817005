@import "../../variables.scss";

.drawerContainer {
    @media ($mobileOnly) {
        padding-top: 1rem;
    }
    @media ($tablet) {
        width: 50%!important;
    }
}

.overlay {
    background-color: transparent;
}

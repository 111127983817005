@import "../../../variables";

$tabHeight: 7*$gridUnit;

.sidePanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - #{16*$gridUnit});

.navigationDiv{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
}

  h4 {
    text-transform: none;
  }
}

.sidePanelWrapper {
  height: 100%;
  overflow: hidden;
  background-color: $greyscaleColour8;
}

.tabs {
  background-color: $greyscaleColour5;
  height: $tabHeight;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.closeTab {
  box-shadow: inset -15px -15px 15px -14px rgba(0, 0, 0, 0.3);
  width: 7*$gridUnit;
}

.closeIcon {
  margin: 1*$gridUnit 1*$gridUnit 1*$gridUnit 1*$gridUnit;
  width: 5*$gridUnit;
  height: 5*$gridUnit;
}

.closeLink {
  &:focus {
    outline: none;
  }
}

.activeTab, .inactiveTab {
  @media only screen and ($mobileOnly) {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: normal;
  }
  font-family: $bodyFontStackRegular;
  color: $greyscaleColour2;
  background-color: $greyscaleColour8;
  width: 220px;
  text-align: center;
  vertical-align: middle;
  line-height: 8*$gridUnit;
  position: center;
  text-decoration: none;
  flex-grow: 1;

  &:focus {
    outline: none;
  }
}

.inactiveTab {
  background-color: $greyscaleColour6;
  box-shadow: inset -15px -15px 15px -14px rgba(0, 0, 0, 0.3);
}

.productInfoDiv {
  width: 80%;
  text-align: center;
}

.productStyleDiv {
  @media only screen and ($mobileOnly) {
    display: block;
  }
  display: flex;
  justify-content: center;
}

.title, .description, .colour {
  margin: 2*$gridUnit 0.5*$gridUnit;

}

.navArrows {
  border-radius: 50%;
  &:hover {
    background-color: $greyscaleColour4;
    border-style: none;
    cursor: pointer;
  }
}

.inactiveButton {
  opacity: .2;
  &:hover {
    background-color: $greyscaleColour8;
  }
}
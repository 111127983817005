@import "../../variables.scss";
@import "../../components/PrimaryButton/primaryButton.scss";
@import "../../components/Typography/typography.scss";
@import "../../components/StyleHelpers/BlackAndWhiteInteractivity/blackAndWhiteInteractivity";

.toggleHierarchy {
  display: flex;
  align-items: center;
}

.hierarchyTrigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $gridUnit*5;
  height: $gridUnit*5;
  padding: 0;
  margin-left: $gridUnit*2.5;
  border-radius: 100%;
  background: $whiteColour;
  outline: none;
  border: 2px solid $blackColour;
  &:hover, &:focus, &:active {
    @include blackAndWhiteInteractivity();
  }
  svg {
    width: 60%;
    height: 60%;
  }
}

.rotateRight {
  transform: rotate(90deg);
}

.selectedHierarchy {
  display: flex;
  align-items: center;
  margin-left: $gridUnit;
  padding: 0 $gridUnit;
  @include bodyText();
  border: 2px solid $blackColour;
  color: $blackColour;
  text-decoration: none;
  background: $whiteColour;
  border-radius: $baseBorderRadius;
  &:hover, &:focus, &:active {
    @include blackAndWhiteInteractivity();
  }
}

.hierarchyTag {
  margin-left: $gridUnit;
}

.hierarchyTagLink {
  border-right: 1px solid black
}
@import "../../../../variables";

$bottomSpace: 3 * $gridUnit;

.container {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: $gridUnit;
}

.productNumber {
  font-family: $bodyFontStackBold;
}

.moveToHierarchy {
  font-size: 1.8 * $gridUnit;
  margin-bottom: $bottomSpace;
}

.folder path {
  fill: $whiteColour;
  stroke: $greyscaleColour3;
}

.confirm {
  font-family: $bodyFontStackBold;
  text-align: center;
  margin-bottom: $bottomSpace;
}

.actionsGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  margin-bottom: $gridUnit;
}

.note {
  font-size: 1.5 * $gridUnit;
  margin-bottom: $bottomSpace;
}
@import "../../../../../variables.scss";

.countryContainer {
  background-color: $greyscaleColour7;
  display: flex;
  padding: 2rem;
  width: 100%;
}

.title {
  color: $blackColour;
  font-weight: bold;
  padding-left: .5rem;
}

.availableCountriesContainer,
.newCountriesContainer {
  background-color: $whiteColour;
  border-radius: .5rem;
  height: 100%;
  overflow-x: auto;
  padding: 1rem;
  width: 50%;
}

.newCountriesContainer {
  margin-right: 2rem;
}

.dropdownWrapper {
  display: flex;
}

.addCountryButton {
  background-color: $whiteColour;
  border-radius: $gridUnit;
  color: $bodyTextColour;
  padding: 0;
  border: 1px solid $activeColour;
  margin-left: 1rem;
  height: 46px;
  width: 5rem;
  &:hover, &:focus {
    background-color: $activeColour;
    color: $whiteColour;
    border: 1px solid $activeColour;
  }
}

.availableCountriesList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.countryTag {
  margin-bottom: $gridUnit * 0.5;
  width: 12rem;
}

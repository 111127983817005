@import "../../variables.scss";

.container {
  overflow-y: scroll;
  height: 100%;
  flex: 0 0 auto;
  width: 42*$gridUnit;
}

.link {
  padding: $gridUnit 4 * $gridUnit ;
  text-decoration: none;
  border-bottom: 1px solid $greyscaleColour7;
  color: $blackColour;
  display: inline-block;
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    color: $activeColour;
    background-color: $greyscaleColour5;
  }

  &.active {
    color: $activeColour;
    background-color: $greyscaleColour5;
  }
}
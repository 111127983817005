@import "../../variables.scss";

$color-red: #f90606;

.lockBanner{
    display: flex;
    margin-right: $gridUnit;
    align-items: center;
    @supports (display: grid) {
    right: unset;
    }
}

.lockBannerTextContainer{
    display: inline-block;
    color: $color-red;
    margin-left:5px;
}

@import "../../../../variables.scss";

.container {
  display: flex;
  flex-direction: column;
  padding: 0 $gridUnit;
  width: 100%;
  background-color: $greyscaleColour7;
  font-size: $gridUnit * 1.5;
  margin-bottom: $gridUnit * 1.5;
}

.title {
  font-weight: bold;
}

.list {
  font-style: italic;
}
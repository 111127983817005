@import "../../../variables.scss";
@import "../../../components/StyleHelpers/FluidProperty/fluidProperty";

@mixin pageGrid($maxWidth: $gridMaximum, $extraClearanceForDropdowns: false) {
    $mobilePaddingBottom: 3*$gridUnit;
    $tabletPaddingBottom: 4*$gridUnit;
    $smallDesktopPaddingBottom: 5*$gridUnit;
    $wideDesktopPaddingBottom: 6*$gridUnit;
    $extraWideDesktopPaddingBottom: 7*$gridUnit;
    $superWideDesktopPaddingBottom: 8*$gridUnit;

    @if($extraClearanceForDropdowns == true){
        $mobilePaddingBottom: $mobilePaddingBottom * 4;
        $tabletPaddingBottom: $tabletPaddingBottom * 4;
        $smallDesktopPaddingBottom: $smallDesktopPaddingBottom * 4;
        $wideDesktopPaddingBottom: $wideDesktopPaddingBottom * 4;
        $extraWideDesktopPaddingBottom: $extraWideDesktopPaddingBottom * 4;
        $superWideDesktopPaddingBottom: $superWideDesktopPaddingBottom * 4;
    }

    max-width: $maxWidth;
    margin: 0 auto;
    @include fluidPropertyBPs(padding-top, 3*$gridUnit, 4*$gridUnit, 5*$gridUnit, 6*$gridUnit, 7*$gridUnit, 8*$gridUnit);
    @include fluidPropertyBPs(padding-bottom, $mobilePaddingBottom, $tabletPaddingBottom, $smallDesktopPaddingBottom, $wideDesktopPaddingBottom, $extraWideDesktopPaddingBottom, $superWideDesktopPaddingBottom);
    padding-left: $gridPadding;
    padding-right: $gridPadding;
}
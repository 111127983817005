@import "../../variables.scss";

.somethingHasBroken {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - #{$gridUnit*28.25});
  background: $whiteColour;
  color: $greyscaleColour3;
}

.errorMessage {
  font-size: $gridUnit*3;
  color: $blackColour;
  margin-bottom: $gridUnit*3;
}

.errorInstructions {
  font-size: $gridUnit*2;
  color: $greyscaleColour3;
  margin-bottom: $gridUnit*3;
}

.brokenShoe {
  margin-bottom: $gridUnit*3;
}

.contentWrapper {
    width: $gridUnit*55;
    text-align: center;
}

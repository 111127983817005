@import "../../../../variables.scss";

.readOnlyCell {
  align-items: center;
  color: black;
  padding: 0.5rem;
  font-size: 14px;
  flex-direction: column;
  overflow-y: auto;
}

.rangeTitleInfo {
  color: $greyscaleColour34
}

.bodyGrid {
  display: flex;
  border-bottom: 1px solid $greyscaleColour3;
  border-right: 1px solid $greyscaleColour3;
}

.columnLeftBorder {
  border-left: 1px solid $greyscaleColour3;
}

.columnRightBorder {
  border-right: 1px solid $greyscaleColour3;
}

.rowTopBorder {
  border-top: 1px solid $greyscaleColour3;  
}

.rowBottomBorder {
  border-bottom: 2px solid $greyscaleColour3;
}

.cellSelectedClass {
  border: 2px solid $activeColour;
}

@import "../../../variables.scss";

.header {
  min-width: 250px;
}

.headerBody {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.closeButtonContainer {
  display: flex;
}

.closeButton {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 100%;
  background: white;
  padding: 0;

  &:focus {
    outline: none;
  }
}

.headerTitle {
  border-bottom: solid lightgray;
  padding-bottom: 12px;
  text-align: center;
}

.styleModalHeader {
  display: flex;
}

.headerImage {
  background-size: cover;
  border: 2px solid $whiteColour;
  height: 9rem;
  width: 10rem;
  object-fit: cover;
}

.productInfo {
  padding-left: 1rem;
}

.developmentId,
.styleDescription,
.hierarchy {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.developmentId {
  font-family: $bodyFontStackLight;
}

.hierarchy,
.styleDescription {
  font-family: $bodyFontStackBold;
}

@import "../../../../../../../variables";
@import "../../../../../../Typography/typography";


.container {
  padding: 0 2*$gridUnit;
  display: flex;
  justify-content: space-evenly;
}

.gridView {
  border-left: 1px solid $greyscaleColour6;
  padding: 0 6*$gridUnit;
}

.header, .rowHeader, .rowData {
  @include bodyTextReduced();
  &::first-letter {
    text-transform: uppercase;
  }
}

.header {
  font-family: $bodyFontStackBold;
  color: $blackColour;
}

.rowHeader, .rowData {
  padding: 0 $gridUnit;
}

.rowData {
  text-align: center;
  width: 7*$gridUnit;
  height: 35px;
}

@import "../../../variables.scss";

.editFormContainer {
  .styleIdContainer {
    padding-bottom: 2*$gridUnit;
  }
  .styleIdTitle {
    color: $greyscaleColour3;
  }
  .styleIdLabel {
    display: inline-block;
    color: $greyscaleColour3;
    font-weight: bold;
    padding: $gridUnit;
    background-color: $whiteColour;
  }
  .confirmButton {
    padding-top: 2*$gridUnit;
  }
  .primaryButton {
    padding: $gridUnit $gridUnit
  }
}

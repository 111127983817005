@import "../../../variables.scss";

.notificationWrapper {
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  overflow: hidden
}

.notificationBox {
  align-items: center;
  border-radius: .2rem;
  border-top: 5px solid #E4E726;
  bottom: auto;
  color: $blackColour;
  display: flex;
  font-family: "Proxima Nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 24px;
  left: 50%;
  line-height: 1.6;
  max-width: 568px;
  min-height: 6rem;
  padding: $gridUnit;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.notificationInfo {
  align-items: center;
  display: flex;
  margin-left: .5rem;
}

.warnIcon {
  min-width: 2.5rem;
  min-height: 2.5rem;
  fill: $accentColour;
}

.warn {
  background-color: $whiteColour;
  height: auto;
}

.closeBtn {
  align-self: flex-start;
  background-color: $greyscaleColour8;
  border-radius: 2.5*$gridUnit;
  border: none;
  height: 4*$gridUnit;
  min-width: 4*$gridUnit;
  padding: 0;
  &:focus, &:active {
    outline: none;
  }
}

.notificationBoxMessage {
  font-family: $bodyFontStackLight;
  padding: .5rem 1rem;
  white-space: pre-wrap;
}

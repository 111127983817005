@import "../../../variables.scss";
@import "../../../components/StyleHelpers/BlackAndWhiteInteractivity/blackAndWhiteInteractivity";

$adminHeaderHeight: 10*$gridUnit;

.adminHeader {
  height: $adminHeaderHeight;
  background-color: $screenBackgroundColour;
  width: 100%;
  padding: 2 * $gridUnit;
  display: flex;
  justify-content: space-between;

}

.attributesButton, .departmentsButton, .columnsButton, .otherToolsButton {
  align-self: center;
  background-color: $whiteColour;
  padding: $gridUnit;
  border-radius: 2*$baseBorderRadius;
  text-align: center;
  width: 20 * $gridUnit;
  color: $blackColour;
  text-decoration: none;
  border: calc($gridUnit/4) solid $blackColour;
  &:hover {
    box-shadow: $baseHeavyBoxShadow;
  }
  &.active {
    box-shadow: $baseHeavyBoxShadow;
    background-color: $blackColour;
    color: $whiteColour;
  }
}

.attributesButton {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: calc($gridUnit/8) solid $blackColour;
}

.departmentsButton {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: calc($gridUnit/8) solid $blackColour;
}

.columnsButton {
  margin-left: 2 * $gridUnit;
  width: 15 * $gridUnit;
}

.otherToolsButton {
  margin-left: 2 * $gridUnit;
  width: 15 * $gridUnit;
}

.newAttributeTrigger {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $gridUnit*5;
  height: $gridUnit*5;
  padding: 0;
  margin-left: $gridUnit*2.5;
  border-radius: 100%;
  background: $whiteColour;
  outline: none;
  border: 2px solid $blackColour;
  &:hover, &:focus, &:active {
    @include blackAndWhiteInteractivity();
  }
  svg {
    width: 60%;
    height: 60%;
  }
}

.newAttributeCallout {
  color: $whiteColour;
}

.navigationLinksContainer {
  display: flex;
}

.newAttributeContainer {
  display: flex;
  align-items: center;
}

@import "../../../variables.scss";
@import "../../../components/StyleHelpers/Background/background.scss";
@import "../../../components/SecondaryButton/secondaryButton.scss";
@import "../../../components/PrimaryButton/primaryButton.scss";
@import "../../../components/Typography/typography.scss";
@import "../../../components/StyleHelpers/PageGrid/pageGrid.scss";
@import "../../../components/StyleHelpers/Panel/panel.scss";

$spacing: 3 * $gridUnit;

.newProductPage {
  @include background(style1);
  @media ($mobileOnly) {
    height: calc(100vh - #{$headerMobileHeight});
  }
}

.pageGrid {
  @include pageGrid($extraClearanceForDropdowns: true);
  align-items: flex-start;
  justify-content: center;
  @media ($mobileOnly) {
    padding-bottom: 0;
  }
}

.floatingTitle {
  @include floatingTitle;
}

.panelAndSketch {
  @media ($tablet){
    display: flex;
    position: relative;
    max-width: 52*$gridUnit;
    margin: 0 auto;
  }
}

.panel {
  display: flex;
  flex-direction: column;
  text-align: center;
  @include panel();
  padding: 0;
  width: 100%;
  @media ($mobileOnly) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  align-items: center;
}

.skipLink {
  border-radius: 0.5 * $gridUnit;
  color: $activeColour;
  padding: $gridUnit;
  text-decoration: none;
  &:hover, &:focus, &:active {
    border: 0.5*$gridUnit solid $activeColour;
    outline: none;
  }
  &:hover {
    box-shadow: $baseBoxShadow;
  }
}

.heading {
  padding: $spacing;
  h3 { margin: 0 }
}

.links {
  width: 100%;
  background-color: white;
  padding-bottom: $spacing;
}

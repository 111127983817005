@import "../../../variables.scss";

.buttonGroup {
  display: flex;
  flex: 99 0 auto;
  justify-content: flex-end;
  margin-top: $gridUnit*4;
  width: 100%;
  text-align: center;

  button, a {
    align-self: flex-end;
  }
  a {
    margin-right: 2 * $gridUnit;
  }
}

.checkBox {
  padding-right: $gridUnit * 8;
}

.container {
  display: flex;
  max-width: 100*$gridUnit;
}

.formGroup {
  display: flex;
  flex: 1 1 50*$gridUnit;
  width: 50*$gridUnit;
  flex-direction: column;
  align-items: center;
  margin: $gridUnit*2;
}

.valueTypeWrapper {
  width: 100%;
  padding:  $gridUnit 0;
  div {
    padding-left: $gridUnit;
  }
}

.valueTypeLabel {
  color: $greyscaleColour3;
}
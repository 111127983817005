@import "../../variables.scss";

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1300;
  height: 100vh;
  width: 100vw;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 4 * $gridUnit;
  border-radius: 2 * $gridUnit;
  background-color: $greyscaleColour9;
}

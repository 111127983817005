@import "../../components/PrimaryButton/primaryButton.scss";
@import "../../variables.scss";

.imagesSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 75vh;
  width: 100%;
}

.buttons {
  padding-top: 1.5*$gridUnit;
  display: flex;
}

.editButton, .addButton {
  @include primaryButton();
  margin-bottom: 2 * $gridUnit;
  width: 20 * $gridUnit;
  height: 5.5 * $gridUnit;
  padding: 0;
  text-align: center;
  &:hover, &:focus, &:active {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.editButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.addButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.title {
  display: flex;
  align-items: center;
  h4 {
    text-transform: capitalize;
    margin-bottom: 0
  }
  margin-bottom: 2 * $gridUnit;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  border-width: 2px;
  border-radius: 2px;
  border-color: $greyscaleColour3;
  border-style: dashed;
  background-color: $whiteColour;
  color: $greyscaleColour3;
  outline: none;
  transition: border .24s ease-in-out;
  margin: 2*$gridUnit;
  &:hover {
    cursor: pointer;
    background-color: $greyscaleColour7;
  }
  &:focus {
    border-color: $activeColour;
  }

  &.disabledDropzone {
    cursor: default;
    border-color: $greyscaleColour6;
    color: $greyscaleColour6;
  }
}

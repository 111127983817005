@import "../../../variables.scss";

.wsImagesScrollArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.wsImagesSectionContainer {
  position: relative;
  @media only screen and ($mobileOnly) {
    width: 90%;
  }
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  @media only screen and ($mobileOnly) {
    overflow-y: auto;
  }
}

.wsImagesContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  @media only screen and ($mobileOnly) {
    overflow-y: auto;
  }
}

.imagePlaceholderContainer {
  padding-bottom: 4rem;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  @media only screen and ($mobileOnly) {
    margin-top: 1rem;
    padding: 1rem 0.5rem;
  }
  border-width: 2px;
  border-radius: 2px;
  border-color: $greyscaleColour3;
  border-style: dashed;
  background-color: $whiteColour;
  color: $greyscaleColour3;
  outline: none;
  transition: border .24s ease-in-out;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    border-color: $activeColour;
  }

  &.disabledDropzone {
    cursor: default;
    border-color: $greyscaleColour6;
    color: $greyscaleColour6;
  }
}

.wholesaleImagesSpinner {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  background: #f1f1f1;
  opacity: 0.5;
  align-items: center;
  justify-content: center;
}

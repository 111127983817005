@import "../../../../../variables";

.section {
  background-color: $whiteColour;
  padding: 2*$gridUnit;
  margin-bottom: $gridUnit;
  border-radius: 5px;
  &:hover {
    box-shadow: $baseBoxShadow;
  }
}

.selectedOption {
  border: 2px solid $greyscaleColour4;
}

.header {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid $greyscaleColour4;
  padding-bottom: $gridUnit*0.5;
}

.title {
  margin-bottom: 0;
}

.supplierTitle {
  margin-bottom: $gridUnit*0.5;
  margin-top: $gridUnit*0.5
}

.productCard {
  border-radius: 5px;
  &:hover {
    box-shadow: $baseBoxShadow;
  }
}

.selectedProductClass {
  background-color: $greyscaleColour7;
}

.removeOptionLink {
  color: $activeColour;
  font-family: $bodyFontStackLight;
  font-size: 1.8*$gridUnit;
  text-decoration: underline;
  width: 3*$gridUnit;
  height: 3*$gridUnit;
  line-height: 1.4;
  border-radius: 100%;
  margin-left: $gridUnit*0.5;
  &:focus, &:active {
    outline: none;
  }
  &:hover {
    box-shadow: $baseBoxShadow;
  }
}

.listItem {
  margin-bottom: 2 * $gridUnit;
  &:last-child {
    margin-bottom: 0;
  }
}

.linkIcon{
  width: 1.75 * $gridUnit;
  margin-right: $gridUnit;
}


@import "../../variables.scss";
$focusedBlackBorderColour: #c7c7c7;
$focusedGreenBorderColour: #A1EECC;
$disabledGreenColour: $focusedGreenBorderColour;
$hoverGreenColour: #289b5f;
$hoverBlackColour: #383938;
$borderSize: 0.5*$gridUnit;

@mixin primaryButton() {
    display: inline-block;
    box-sizing: border-box;
    padding: $gridUnit*2 $gridUnit*4;
    font-size: 1rem;
    background-color: $blackColour;
    color: $whiteColour;
    border: 0.5*$gridUnit solid $blackColour;
    border-radius: $baseBorderRadius;
    text-decoration: none;
}

.button {
    @include primaryButton();

    &:hover, &:focus, &:active {
        border: 0.5*$gridUnit solid $focusedBlackBorderColour;
        outline: none;
    }
    &:hover {
      box-shadow: $baseBoxShadow;
      background-color: $hoverBlackColour;
      border: 0.5*$gridUnit solid $hoverBlackColour;
    }

    &:disabled {
      background-color: $greyscaleColour4;
      border: $borderSize solid $greyscaleColour4;
      cursor: not-allowed;
      &:hover {
        box-shadow: none;
      }
    }

    &.green {
      background-color: $successColour;
      border: 0.5*$gridUnit solid $successColour;

      &:focus, &:active {
        border: 0.5*$gridUnit solid $focusedGreenBorderColour;
        outline: none;
      }
      &:hover {
        border: 0.5*$gridUnit solid $hoverGreenColour;
        background-color: $hoverGreenColour;
      }
      &:disabled {
        background-color: $disabledGreenColour;
        border: $borderSize solid $disabledGreenColour;
      }
    }
}

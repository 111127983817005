@import "../../variables.scss";
@import "../../components/Typography/typography";

.tagLink {
  text-decoration: none;
  color: $blackColour;
  justify-content: center;
  height: 100%;
  border-right: 1px solid lighten($greyscaleColour2, 40%);
  align-items: center;
  display: flex;
  background-color: inherit;

  &:hover, &:focus {
    filter: brightness(80%);
    outline: none;
  }
}

.tag{
  @include bodyTextReduced();
  display: flex;
  align-items: center
}

.hierarchyTag {
  background-color: $whiteColour;
  border-radius: $gridUnit;
  height: 4.5*$gridUnit;

  .tagLink {
    width: 4.5*$gridUnit;
    border-top-left-radius: $gridUnit;
    border-bottom-left-radius: $gridUnit;
    border-right: 1px solid $greyscaleColour6;
  }
}

.filterTag {
  background-color: $accentColour;
  border-radius: $baseBorderRadius;

  .tagLink {
    width: 4*$gridUnit;
    border-top-left-radius: $baseBorderRadius;
    border-bottom-left-radius: $baseBorderRadius;
  }
}



.listTag {
  border-radius: $baseBorderRadius;
  margin: $gridUnit*0.25;
  background-color: $whiteColour;
  border: 1px solid $screenBackgroundColour;
  color: $blackColour;

  .tagLink {
    border-top-left-radius: $baseBorderRadius;
    border-bottom-left-radius: $baseBorderRadius;
    width: 2*$gridUnit;
    color: $blackColour;
  }
}

.tagText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5 * $gridUnit $gridUnit 0.5 * $gridUnit $gridUnit;
}
@import "../../../../variables.scss";

.searchFormContainer {
  height: 45*$gridUnit;
  .formRow {
    display: flex;
    justify-content: space-between;
    .formItem {
      padding: 0.5 * $gridUnit;
      width: 100%;
    }
  }
}

.searchButton {
  display: flex;
  justify-content: flex-end;
  &.resultsAvailable {
    justify-content: space-between
  }
  padding-top: 4*$gridUnit;
}
.primaryButton {
  padding: $gridUnit $gridUnit
}

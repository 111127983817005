@import "../../variables.scss";

@mixin changeStatusButton() {
    display: inline-block;
    padding: $gridUnit*2 $gridUnit*4;
    font-size: 1rem;
    background-color: $blackColour;
    color: $whiteColour;
    border: 0.5*$gridUnit solid $blackColour;
    border-radius: $baseBorderRadius;
    text-decoration: none;
    &[disabled]{
      background-color: $greyscaleColour6;
      color: $greyscaleColour3;
      border-color: $greyscaleColour6;
      text-shadow: 0 1px 0 rgba(255, 255, 255, 0.6);
      cursor: not-allowed;
    }
    &:hover, &:focus, &:active {
      box-shadow: $baseHeavyBoxShadow;
    }
}
